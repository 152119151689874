import React, { useEffect, useState } from "react";
import { get } from "../../utility/api";
import GospelSelectBox from "../../common/GospelSelect/GospelSelect";
import GospelButton from "../../common/GospelButton/GospelButton";
import {
  BrandChannels,
  BrandInfo,
  VideoSearchResponse,
} from "../../types/channel";
import { CircularProgress } from "@mui/material";
import GospelLabel from "../../common/GospelLabel/GospelLabel";
import GospelInput from "../../common/GospelInput/GospelInput";
import {
  VideoSearchTable,
  orderByOptions,
  videoLengthOptions,
  videoSorOptions,
} from "./BrandUtils";
import {
  formatNumberWithCommas,
  removeCommasAndConvertToInt,
} from "../../utility/common";

interface VideoSearchProps {
  brandInfo?: BrandInfo;
}

const VideoSearch: React.FC<VideoSearchProps> = ({ brandInfo }) => {
  const [minViews, setMinViews] = useState<string>("");
  const [maxViews, setMaxViews] = useState<string>("");
  const [brandChannels, setBrandChannels] = useState([
    { value: "", label: "" },
  ]);
  const [selectedChannel, setSelectedChannel] = useState("all");
  const [videoSearchResults, setVideoSearchResults] =
    useState<VideoSearchResponse>({} as VideoSearchResponse);
  const [selectedVideoLength, setSelectedVideoLength] = useState("both");
  const [selectedVideoSort, setSelectedVideoSort] =
    useState<string>("uploadDate");
  const [selectedOrderBy, setSelectedOrderBy] = useState<string>("desc");

  const [offset, setOffset] = useState<number>(0); // Start offset at 0
  const [limit] = useState<number>(25); // Fixed limit per page
  const [totalResults, setTotalResults] = useState<number>(0); // Track total results
  const [currentPage, setCurrentPage] = useState<number>(1); // Current page tracking

  const [videoSearchLoading, setVideoSearchLoading] = useState<boolean>(false);

  const fetchChannels = async () => {
    try {
      const response = await get<BrandChannels[]>(
        `/gospel/brand/data/listchannels?brand_id=${brandInfo?.id}`
      );
      const formattedChannels = [
        { label: "All", value: "all" }, // Adding "All" as the first option
        ...response.map((item) => ({
          label: item.channelTitle,
          value: item.channelInfoId.toString(),
        })),
      ];
      setBrandChannels(formattedChannels);
    } catch (error) {
      console.error("Error fetching brand channels:", error);
    }
  };

  useEffect(() => {
    fetchChannels();
  }, []);

  const onSearchVideos = async (newOffset: number = 0) => {
    setVideoSearchLoading(true);
    try {
      const response = await get<VideoSearchResponse>(
        `/gospel/brand/data/findvideos?brand_id=${brandInfo?.id}&minviews=${minViews}&maxviews=${maxViews}&creators=${selectedChannel}&length=${selectedVideoLength}&sortby=${selectedVideoSort}&orderby=${selectedOrderBy}&limit=${limit}&offset=${newOffset}`
      );
      setVideoSearchResults(response);
      setOffset(newOffset); // Update offset
      setCurrentPage(newOffset / limit + 1); // Update current page
      setTotalResults(response.resultCount || 0); // Set total results
    } catch (error) {
      console.error("Error fetching video data:", error);
    } finally {
      setVideoSearchLoading(false);
    }
  };

  const handleFirstPage = () => {
    if (currentPage > 1) {
      onSearchVideos(0);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const newOffset = offset - limit;
      onSearchVideos(newOffset);
    }
  };

  const handleNextPage = () => {
    if (videoSearchResults.items && videoSearchResults.items.length === limit) {
      const newOffset = offset + limit;
      onSearchVideos(newOffset);
    }
  };

  return (
    <div className="px-4 mb-12">
      <div className="flex flex-col bg-white shadow-md py-8 items-center">
        <span className="text-xl font-semibold text-center">
          {`${brandInfo?.name} Video Search`}
        </span>
        <div className="my-8 w-full flex flex-col justify-center items-center gap-8">
          <div className="flex flex-row items-center gap-3">
            <GospelLabel
              label={"Total Video Views:"}
              className="text-sm text-gray-400 w-48"
            />
            <GospelInput
              type="text"
              onChange={(e) => setMinViews(e.target.value)}
              value={formatNumberWithCommas(minViews)}
              placeholder="Min"
              className="h-10 w-80 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
            />
            <span className="text-gray-400">-</span>
            <GospelInput
              type="text"
              onChange={(e) => setMaxViews(e.target.value)}
              value={formatNumberWithCommas(maxViews)}
              placeholder="Max"
              className="h-10 w-80 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
            />
          </div>
          <div className="flex flex-row items-center gap-3">
            <GospelLabel
              label={"Channel:"}
              className="text-sm text-gray-400 w-48"
            />
            <GospelSelectBox
              options={brandChannels}
              value={selectedChannel}
              onChange={(value) => setSelectedChannel(value)}
              containerClass="relative"
              className="w-[670px] h-10"
              placeholder="Select"
            />
          </div>
          <div className="flex flex-row items-center gap-3">
            <GospelLabel
              label={"Video Length:"}
              className="text-sm text-gray-400 w-48"
            />
            <GospelSelectBox
              options={videoLengthOptions}
              value={selectedVideoLength}
              onChange={(value) => setSelectedVideoLength(value)}
              containerClass="relative"
              className="w-[670px] h-10"
              placeholder="Select"
            />
          </div>
          <GospelButton
            className="text-white h-[40px] w-[90px] rounded-md text-sm font-medium bg-gospel-purple hover:bg-gospel-purple-dark ml-3"
            label="Search"
            onClick={() => onSearchVideos(0)}
          />
        </div>

        {videoSearchLoading ? (
          <div>
            <CircularProgress
              className="text-gospel-gray-300 font-bold z-10"
              size={30}
            />
          </div>
        ) : videoSearchResults.resultCount &&
          videoSearchResults.resultCount > 0 ? (
          <div className="px-8 flex items-center flex-col w-full">
            <p>{`Total Results: ${videoSearchResults.resultCount}`}</p>
            <div className="flex flex-row w-full items-center justify-between my-4">
              <div className="flex flex-row items-center">
                <GospelLabel
                  label={"Sort By:"}
                  className="text-sm text-gray-400 w-24"
                />
                <GospelSelectBox
                  options={videoSorOptions}
                  value={selectedVideoSort}
                  onChange={(value) => setSelectedVideoSort(value)}
                  containerClass="relative"
                  className="w-96 h-10"
                  placeholder="Select"
                />
              </div>
              <div className="flex flex-row items-center">
                <GospelLabel
                  label={"Order By:"}
                  className="text-sm text-gray-400 w-24"
                />
                <GospelSelectBox
                  options={orderByOptions}
                  value={selectedOrderBy}
                  onChange={(value) => setSelectedOrderBy(value)}
                  containerClass="relative"
                  className="w-96 h-10"
                  placeholder="Select"
                />
              </div>
            </div>
            <VideoSearchTable
              data={videoSearchResults.items}
              selectedOrderBy={selectedOrderBy}
              selectedVideoSort={selectedVideoSort}
            />
            <div className="flex justify-center gap-4 mt-6">
              {currentPage !== 1 && (
                <>
                  <GospelButton
                    className="bg-gospel-white border border-gray-300 text-gospel-purple px-4 py-2 hover:text-gospel-gray-300 rounded text-sm"
                    label="First"
                    onClick={handleFirstPage}
                  />

                  <GospelButton
                    className="bg-gospel-white border border-gray-300 text-gospel-purple px-4 py-2 hover:text-gospel-gray-300 rounded text-sm"
                    label="Previous"
                    onClick={handlePreviousPage}
                  />
                </>
              )}
              <GospelButton
                className="bg-gospel-white border border-gray-300 text-gospel-purple px-4 py-2 hover:text-gospel-gray-300 rounded text-sm"
                label="Next"
                disabled={
                  videoSearchResults.items.length < limit ||
                  offset + limit >= totalResults
                }
                onClick={handleNextPage}
              />
            </div>
          </div>
        ) : (
          !videoSearchLoading &&
          videoSearchResults.resultCount === 0 && (
            <div className="text-gospel-error-red text-base bg-gospel-red-100 px-6 my-2 py-4 rounded w-4/5 text-center">
              {"No further results matching that criteria were found."}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default VideoSearch;
