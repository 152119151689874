import React, { useState } from "react";
import GospelButton from "../../common/GospelButton/GospelButton";
import { useNavigate } from "react-router-dom";
import {
  BrandTrendingVideoInfo,
  ChannelSearchData,
  VideoSearchData,
} from "../../types/channel";
import { Cancel, PlayArrow } from "@mui/icons-material";
import moment from "moment";
import { ConfigProvider, DatePicker, Modal } from "antd";
import dayjs from "dayjs";
import ReactPlayer from "react-player";
import { formatNumberWithCommas } from "../../utility/common";

const TrendingVideoTable = ({
  data,
  showTrendingVideos,
}: {
  data: BrandTrendingVideoInfo[];
  showTrendingVideos: boolean;
}) => {
  const navigate = useNavigate();
  const [isYoutubeOpen, setIsYoutubeOpen] = useState<boolean>(false);
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);

  const openModal = (videoId: string) => {
    setSelectedVideoId(videoId);
    setIsYoutubeOpen(true);
  };

  const closeModal = () => {
    setIsYoutubeOpen(false);
    setSelectedVideoId(null);
  };
  return (
    <table className="divide-y divide-gray-200 w-full mx-8">
      <thead>
        <tr className="text-center text-base text-gospel-gray-300">
          <th className="w-[70%]"></th>
          <th className="py-2 w-[10%] font-medium">Channel</th>
          <th className="py-2 w-[10%] font-medium">Views</th>
          <th className="py-2 w-[10%] font-medium">Comments</th>
        </tr>
      </thead>
      <tbody>
        {data
          .slice(0, showTrendingVideos ? data.length : 3)
          .map((item, index) => (
            <tr
              key={item.videoId}
              className={`text-center text-sm text-gray-800 ${
                index % 2 === 0 ? "bg-gospel-white-100" : "bg-transparent"
              }`}
            >
              <td className="py-2 w-[70%] flex flex-row gap-4 items-center">
                <div
                  className=" bg-gray-200 flex items-center justify-center cursor-pointer"
                  onClick={() => openModal(item.videoId)}
                >
                  <img
                    src={item.thumbnailURL}
                    alt="Video Thumbnail"
                    className="max-w-24 h-14"
                  />
                  <PlayArrow htmlColor="#fff" className="absolute" />
                </div>
                <div className="flex flex-col text-left">
                  <h3
                    className="font-medium text-gospel-gray-300 cursor-pointer"
                    onClick={() => openModal(item.videoId)}
                  >
                    {item.title}
                  </h3>
                  <div className="flex flex-row">
                    <p className="text-sm text-gospel-gray-200 ">
                      {item.uploadDate}
                    </p>
                    <p className="mx-1 text-sm text-gospel-gray-200 ">| </p>
                    <p className="text-sm text-gospel-gray-200 ">
                      {moment(item.uploadTimestamp).format("MM/DD/YYYY, HH:mm")}
                    </p>
                  </div>
                </div>
              </td>
              <td
                className="py-1 w-[10%] text-gospel-purple text-base cursor-pointer"
                onClick={() => navigate(`/channel/${item.gospelPageSlug}`)}
              >
                {item.gospelPageTitle}
              </td>
              <td className="py-2 w-[10%] text-gospel-gray-200 text-base">
                {formatNumberWithCommas(item.viewCount)}
              </td>
              <td className="py-2 w-[10%] text-gospel-gray-200 text-base">
                {formatNumberWithCommas(item.commentCount)}
              </td>
            </tr>
          ))}
        {selectedVideoId && (
          <ConfigProvider
            theme={{
              components: {
                Modal: {
                  titleFontSize: 18,
                  headerBg: "transparent",
                  contentBg: "",
                  titleColor: "#353b3d",
                  boxShadow: "none",
                  fontFamily: "Montserrat, sans-serif",
                },
              },
            }}
          >
            <Modal
              title=""
              open={isYoutubeOpen}
              width={"fit-content"}
              height={"fit-content"}
              footer={null}
              closeIcon={
                <Cancel
                  className="absolute bg-gospel-purple text-white rounded-full bottom-0"
                  fontSize="large"
                  onClick={closeModal}
                />
              }
              className="text-center"
            >
              <ReactPlayer
                controls
                width="750px"
                height={"450px"}
                url={`https://youtube.com/watch?v=${selectedVideoId}`}
              />
            </Modal>
          </ConfigProvider>
        )}
      </tbody>
    </table>
  );
};

const ChannelSearchVideosTable = ({
  data,
  brandName,
  selectedSort,
  selectedOrderBy,
}: {
  data: ChannelSearchData[];
  brandName: string | undefined;
  selectedSort: string;
  selectedOrderBy: string;
}) => {
  const navigate = useNavigate();
  const [isYoutubeOpen, setIsYoutubeOpen] = useState<boolean>(false);
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);

  const sortedData = data.sort((a, b) => {
    let comparison = 0;
    if (selectedSort === "views") {
      comparison = a.lastUploadViewCount - b.lastUploadViewCount;
    } else if (selectedSort === "videos") {
      comparison = a.videos - b.videos;
    }
    return selectedOrderBy === "asc" ? comparison : -comparison;
  });

  return (
    <>
      <table className="divide-y divide-gray-200 w-full mx-8">
        <thead>
          <tr className="text-center text-base text-gospel-gray-300">
            <th className="px-4 py-2"></th>
            <th className="px-4 py-2 font-medium">Channel</th>
            <th className="px-4 py-2 font-medium">{`Videos with ${brandName}`}</th>
            <th className="px-4 py-2 font-medium">{`Last ${brandName} Sponsored Video`}</th>
            <th className="px-4 py-2 font-medium">Views</th>
            <th className="px-4 py-2 font-medium">Comments</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr
              key={item.lastUploadId}
              className={`text-center text-sm text-gray-800 ${
                index % 2 === 0 ? "bg-gospel-white-100" : "bg-transparent"
              }`}
            >
              <td className="px-4 py-2 w-24">
                <img
                  src={item.channelImage}
                  className="w-16 h-16 rounded-full"
                  alt="title"
                />
              </td>
              <td
                className="px-4 py-2 text-gospel-purple text-base cursor-pointer"
                onClick={() => navigate(`/channel/${item.gospelPageSlug}`)}
              >
                {item.channelTitle}
              </td>

              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                {item.videos}
              </td>

              <td className="px-4 py-2 flex flex-row gap-4 items-center">
                <div
                  className=" bg-gray-200 flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    setSelectedVideoId(item.lastUploadId);
                    setIsYoutubeOpen(true);
                  }}
                >
                  <img
                    src={item.lastUploadThumbnail}
                    alt="Video Thumbnail"
                    className="max-w-32 h-16"
                  />
                  <PlayArrow htmlColor="#fff" className="absolute" />
                </div>
                <div className="flex flex-col text-left">
                  <h3
                    className="font-medium text-gospel-gray-300 cursor-pointer"
                    onClick={() => {
                      setSelectedVideoId(item.lastUploadId);
                      setIsYoutubeOpen(true);
                    }}
                  >
                    {item.lastUploadTitle}
                  </h3>
                  <div className="flex flex-row">
                    <p className="text-sm text-gospel-gray-200 ">
                      {item.lastUploadDate}
                    </p>
                    <p className="mx-1 text-sm text-gospel-gray-200 ">| </p>
                    <p className="text-sm text-gospel-gray-200 ">
                      {moment(item.lastUploadTimestamp).format(
                        "MM/DD/YYYY, HH:mm"
                      )}
                    </p>
                  </div>
                </div>
              </td>

              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                {formatNumberWithCommas(item.lastUploadViewCount)}
              </td>
              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                {formatNumberWithCommas(item.lastUploadCommentCount)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedVideoId && (
        <ConfigProvider
          theme={{
            components: {
              Modal: {
                titleFontSize: 18,
                headerBg: "transparent",
                contentBg: "",
                titleColor: "#353b3d",
                boxShadow: "none",
                fontFamily: "Montserrat, sans-serif",
              },
            },
          }}
        >
          <Modal
            title=""
            open={isYoutubeOpen}
            width={"fit-content"}
            height={"fit-content"}
            footer={null}
            closeIcon={
              <Cancel
                className="absolute bg-gospel-purple text-white rounded-full bottom-0"
                fontSize="large"
                onClick={() => {
                  setIsYoutubeOpen(false);
                  setSelectedVideoId(null);
                }}
              />
            }
            className="text-center"
          >
            <ReactPlayer
              controls
              width="750px"
              height={"450px"}
              url={`https://youtube.com/watch?v=${selectedVideoId}`}
            />
          </Modal>
        </ConfigProvider>
      )}
    </>
  );
};

const VideoSearchTable = ({
  data,
  selectedVideoSort,
  selectedOrderBy,
}: {
  data: VideoSearchData[];
  selectedVideoSort: string;
  selectedOrderBy: string;
}) => {
  const navigate = useNavigate();
  const [isYoutubeOpen, setIsYoutubeOpen] = useState<boolean>(false);
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);

  const sortedData = data.sort((a, b) => {
    let comparison = 0;

    if (selectedVideoSort === "views") {
      comparison = a.lastUploadViewCount - b.lastUploadViewCount;
    } else if (selectedVideoSort === "uploadDate") {
      const dateA = new Date(a.lastUploadTimestamp).getTime();
      const dateB = new Date(b.lastUploadTimestamp).getTime();
      comparison = dateA - dateB;
    }

    return selectedOrderBy === "asc" ? comparison : -comparison;
  });

  return (
    <>
      <table className="divide-y divide-gray-200 w-full mx-8">
        <thead>
          <tr className="text-center text-base text-gospel-gray-300">
            <th className="px-4 py-2 font-medium">Video</th>
            <th className="px-4 py-2 font-medium">Channel</th>
            <th className="px-4 py-2 font-medium">Views</th>
            <th className="px-4 py-2 font-medium">Comments</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr
              key={item.lastUploadId}
              className={`text-center text-sm text-gray-800 ${
                index % 2 === 0 ? "bg-gospel-white-100" : "bg-transparent"
              }`}
            >
              <td className="px-4 py-2 flex flex-row gap-4 items-center">
                <div
                  className=" bg-gray-200 flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    setSelectedVideoId(item.lastUploadId);
                    setIsYoutubeOpen(true);
                  }}
                >
                  <img
                    src={item.lastUploadThumbnail}
                    alt="Video Thumbnail"
                    className="max-w-32 h-16"
                  />
                  <PlayArrow htmlColor="#fff" className="absolute" />
                </div>
                <div className="flex flex-col text-left">
                  <h3
                    className="font-medium text-gospel-gray-300 cursor-pointer"
                    onClick={() => {
                      setSelectedVideoId(item.lastUploadId);
                      setIsYoutubeOpen(true);
                    }}
                  >
                    {item.lastUploadTitle}
                  </h3>
                  <div className="flex flex-row">
                    <p className="text-sm text-gospel-gray-200 ">
                      {item.lastUploadDate}
                    </p>
                    <p className="mx-1 text-sm text-gospel-gray-200 ">| </p>
                    <p className="text-sm text-gospel-gray-200 ">
                      {moment(item.lastUploadTimestamp).format(
                        "MM/DD/YYYY, HH:mm"
                      )}
                    </p>
                  </div>
                </div>
              </td>

              <td
                className="px-4 py-2 text-gospel-purple text-base cursor-pointer"
                onClick={() => navigate(`/channel/${item.gospelPageSlug}`)}
              >
                {item.channelTitle}
              </td>

              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                {formatNumberWithCommas(item.lastUploadViewCount)}
              </td>
              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                {item.lastUploadCommentCount}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedVideoId && (
        <ConfigProvider
          theme={{
            components: {
              Modal: {
                titleFontSize: 18,
                headerBg: "transparent",
                contentBg: "",
                titleColor: "#353b3d",
                boxShadow: "none",
                fontFamily: "Montserrat, sans-serif",
              },
            },
          }}
        >
          <Modal
            title=""
            open={isYoutubeOpen}
            width={"fit-content"}
            height={"fit-content"}
            footer={null}
            closeIcon={
              <Cancel
                className="absolute bg-gospel-purple text-white rounded-full bottom-0"
                fontSize="large"
                onClick={() => {
                  setIsYoutubeOpen(false);
                  setSelectedVideoId(null);
                }}
              />
            }
            className="text-center"
          >
            <ReactPlayer
              controls
              width="750px"
              height={"450px"}
              url={`https://youtube.com/watch?v=${selectedVideoId}`}
            />
          </Modal>
        </ConfigProvider>
      )}
    </>
  );
};

const timeFrameOptions = [
  {
    value: "allTime",
    label: "All Time",
  },
  {
    value: "lastYear",
    label: "Last Year",
  },
  {
    value: "last6Months",
    label: "Last 6 Months",
  },
  {
    value: "last90Days",
    label: "Last 90 Days",
  },
];

const videoLengthOptions = [
  {
    value: "shorts",
    label: "Shorts Only",
  },
  {
    value: "longs",
    label: "Longs Only",
  },

  {
    value: "both",
    label: "Shorts and Longs",
  },
];

const videoSorOptions = [
  {
    value: "uploadDate",
    label: "Upload Date",
  },
  {
    value: "views",
    label: "Views",
  },
];

const orderByOptions = [
  {
    value: "desc",
    label: "Descending",
  },
  {
    value: "asc",
    label: "Ascending",
  },
];

const brandChartTabs = [
  {
    key: 1,
    label: "Sponsored Videos",
    dataGraph: "scatterplot",
    dataColor: "#9D63B0",
    dataHide: "",
    keyName: "views",
  },
  {
    key: 2,
    label: "# of Sponsored Videos",
    dataGraph: "videos",
    dataColor: "#7375db",
    dataHide: "hourly",
    keyName: "count",
  },
  // {
  //   key: 3,
  //   label: "Views on Sponsored Videos",
  //   dataGraph: "views",
  //   dataColor: "#64c0ea",
  //   dataHide: "hourly",
  //   keyName: "count",
  // },
];

export {
  TrendingVideoTable,
  ChannelSearchVideosTable,
  VideoSearchTable,
  timeFrameOptions,
  videoLengthOptions,
  videoSorOptions,
  orderByOptions,
  brandChartTabs,
};
