import React, { ReactNode } from "react";

interface GospelButtonProps {
  label: string | ReactNode;
  onClick: () => void;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const GospelButton: React.FC<GospelButtonProps> = ({
  label,
  onClick,
  className = "",
  style = {},
  disabled,
}) => {
  return (
    <button
      className={className}
      onClick={onClick}
      style={{ ...style, opacity: disabled ? 0.6 : 1 }}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default GospelButton;
