import React, { useEffect, useState } from "react";
import Header from "../../common/Header/Header";

import GospelButton from "../../common/GospelButton/GospelButton";
import { useNavigate, useParams } from "react-router-dom";

import { Cancel, RemoveRedEyeSharp } from "@mui/icons-material";
import moment from "moment";
import { Avatar, ConfigProvider, Modal } from "antd";
import { get } from "../../utility/api";
import { CircularProgress } from "@mui/material";
import { formatNumberWithCommas } from "../../utility/common";
import { CategoryDetails, CategoryStats } from "../../types/channel";
import ReactPlayer from "react-player";

function Category() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [categoryInfo, setCategoryInfo] = useState<CategoryStats>();
  const [loading, setLoading] = useState<boolean>(false);
  const [brandStats, setBrandStats] = useState<any>([]);
  const [showTopBrands, setShowTopBrands] = useState<boolean>(false);
  const [isYoutubeOpen, setIsYoutubeOpen] = useState<boolean>(false);
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);

  const toggleShowTopBrand = () => {
    setShowTopBrands(!showTopBrands);
  };

  const openModal = (videoId: string) => {
    setSelectedVideoId(videoId);
    setIsYoutubeOpen(true);
  };

  const closeModal = () => {
    setIsYoutubeOpen(false);
    setSelectedVideoId(null);
  };

  const handleChannelClick = (gospelUrl: string) => {
    const slug = gospelUrl.split("/").pop(); // Extract the last part of the URL
    navigate(`/channel/${slug}`); // Navigate to the channel page
  };

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        setLoading(true);

        const result = await get<CategoryDetails>(
          `gospel/brand/category/info?slug=${slug}`
        );
        if (result) {
          const response = await get<CategoryStats>(
            `gospel/brand/category/overview?id=${result.categoryId}`
          );
          setCategoryInfo(response);

          const brandStatisticsArray = [
            {
              category: "Views on Sponsored Videos",
              stats: [
                {
                  title: "7 Day Views",
                  key: "sevenDayViews",
                  value: response.sevenDayViewCount,
                },
                {
                  title: "30 Day Views",
                  key: "thirtyDayViews",
                  value: response.thirtyDayViewCount,
                },
                {
                  title: "90 Day Views",
                  key: "ninetyDayViews",
                  value: response.ninetyDayViewCount,
                },
              ],
            },
            {
              category: "# of Sponsored Videos",
              stats: [
                {
                  title: "7 Day Videos",
                  key: "sevenDayVideoCount",
                  value: response.sevenDayVideoCount,
                },
                {
                  title: "30 Day Videos",
                  key: "thirtyDayVideoCount",
                  value: response.thirtyDayVideoCount,
                },
                {
                  title: "90 Day Videos",
                  key: "ninetyDayVideoCount",
                  value: response.ninetyDayVideoCount,
                },
              ],
            },
          ];
          setBrandStats(brandStatisticsArray);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchCategory();
  }, [slug]);

  return (
    <div className="overflow-hidden flex flex-col min-h-screen">
      <Header />
      <div className="w-full flex flex-col py-12 items-center  bg-gospel-black-100 justify-center mt-[70px] gap-3">
        <span className="text-base leading-tight font-medium text-gospel-gray-400">
          {"Category"}
        </span>

        <span className="text-2xl leading-tight font-semibold text-white">
          {`${slug?.charAt(0).toUpperCase()}${slug?.slice(1)}`}
        </span>
      </div>
      <div className="m-8">
        {loading ? (
          <div className="p-8 flex items-center justify-center bg-white rounded-md">
            <CircularProgress
              className="text-gospel-gray-300 font-bold z-10"
              size={30}
            />
          </div>
        ) : (
          <>
            <div className="flex bg-white shadow-md p-8 justify-between gap-8 rounded-md">
              {brandStats && brandStats.length > 0 && (
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-8">
                  {brandStats.map((category: any, index: number) => (
                    <div key={index} className="w-full">
                      {/* Category Header */}
                      <div className="py-5 border-b-2 border-gray-300 w-full text-center">
                        <span className="text-xl font-semibold text-center">
                          {category.category}
                        </span>
                      </div>

                      {/* List of Stats */}
                      {category.stats.map((data: any) => (
                        <div
                          className="w-full py-5 border-b-2 border-gray-300 relative  flex items-center justify-between"
                          key={data.key}
                        >
                          <div>
                            <span className="text-lg font-semibold inline-block relative">
                              {data.title}
                            </span>
                          </div>

                          <div className="flex items-center gap-2">
                            <span className="float-right text-lg font-semibold text-gray-800">
                              {formatNumberWithCommas(data.value)}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className="m-8">
        {loading ? (
          <div className="p-8 flex items-center justify-center bg-white rounded-md">
            <CircularProgress
              className="text-gospel-gray-300 font-bold z-10"
              size={30}
            />
          </div>
        ) : (
          <div className="flex flex-col bg-white shadow-md px-4 py-8 rounded-md">
            {/* Main Title */}
            <h1 className="text-2xl font-bold mb-4 text-center">
              Top Food Brands in Last 90 Days
            </h1>

            {/* Table Container */}
            <div className="overflow-x-auto my-4">
              <table className="min-w-full divide-y divide-gray-200">
                {/* Table Head */}
                <thead>
                  <tr className="text-left text-base text-gospel-gray-300">
                    <th className="px-4 py-2"></th>
                    <th className="px-4 py-2 font-medium">Brand</th>
                    <th className="px-4 py-2 font-medium">Videos</th>
                    <th className="px-4 py-2 font-medium">Views</th>
                    <th className="px-4 py-2 font-medium">Most Viewed Video</th>
                    <th className="px-4 py-2 font-medium">
                      Frequent Collaborators
                    </th>
                  </tr>
                </thead>
                {/* Table Body */}
                <tbody>
                  {categoryInfo?.topBrands
                    .slice(0, showTopBrands ? categoryInfo.topBrands.length : 5)
                    .map((item, index) => {
                      return (
                        <>
                          <tr
                            className={`text-left text-sm text-gray-800  ${
                              index % 2 === 0
                                ? "bg-gospel-white-100"
                                : "bg-transparent"
                            }`}
                            key={item.brandName}
                          >
                            <td className="p-4 w-28">
                              <Avatar
                                src={
                                  <img
                                    alt={item.brandName}
                                    src={item.brandImage}
                                  />
                                }
                                style={{ height: 50, width: 50 }}
                              />
                            </td>

                            <td className="p-4 w-48">
                              <a
                                href={`/brand/${item.pageSlug}`}
                                className="text-gospel-purple cursor-pointer hover:text-black text-base"
                              >
                                {item.brandName}
                              </a>
                            </td>

                            <td className="p-4 text-gospel-gray-200 text-base w-36">
                              {formatNumberWithCommas(item.videoCount)}
                            </td>

                            <td className="p-4 text-gospel-gray-200 text-base w-36">
                              {formatNumberWithCommas(item.totalVideoViews)}
                            </td>

                            <td
                              className="p-4 text-gospel-purple cursor-pointer hover:text-black text-base"
                              onClick={() =>
                                openModal(item.mostViewedVideo.videoId)
                              }
                            >
                              {item.mostViewedVideo.title}
                            </td>

                            <td className="p-4 text-gospel-purple text-base">
                              {item.collaborations.map(
                                (collaboration, index) => (
                                  <span key={collaboration.gospelUrl}>
                                    <span
                                      className="cursor-pointer hover:text-black"
                                      onClick={() =>
                                        handleChannelClick(
                                          collaboration.gospelUrl
                                        )
                                      }
                                    >
                                      {collaboration.channelTitle}
                                    </span>
                                    {/* Add comma if it's not the last element */}
                                    {index < item.collaborations.length - 1 &&
                                      ", "}
                                  </span>
                                )
                              )}
                            </td>
                          </tr>
                          {selectedVideoId && (
                            <ConfigProvider
                              theme={{
                                components: {
                                  Modal: {
                                    titleFontSize: 18,
                                    headerBg: "transparent",
                                    contentBg: "",
                                    titleColor: "#353b3d",
                                    boxShadow: "none",
                                    fontFamily: "Montserrat, sans-serif",
                                  },
                                },
                              }}
                            >
                              <Modal
                                title=""
                                open={isYoutubeOpen}
                                width={"fit-content"}
                                height={"fit-content"}
                                footer={null}
                                closeIcon={
                                  <Cancel
                                    className="absolute bg-gospel-purple text-white rounded-full bottom-0"
                                    fontSize="large"
                                    onClick={closeModal}
                                  />
                                }
                                className="text-center"
                              >
                                <ReactPlayer
                                  controls
                                  width="750px"
                                  height={"450px"}
                                  url={`https://youtube.com/watch?v=${selectedVideoId}`}
                                />
                              </Modal>
                            </ConfigProvider>
                          )}
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {categoryInfo?.topBrands && categoryInfo?.topBrands.length > 2 && (
              <GospelButton
                className="bg-white px-2 self-center my-2 py-2 w-36 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
                label={showTopBrands ? "Show Less" : "View More"}
                onClick={toggleShowTopBrand}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Category;
