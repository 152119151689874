import React, { useEffect, useState } from "react";
import GospelLabel from "../../common/GospelLabel/GospelLabel";
import GospelInput from "../../common/GospelInput/GospelInput";
import GospelSelectBox from "../../common/GospelSelect/GospelSelect";
import GospelButton from "../../common/GospelButton/GospelButton";
import { get } from "../../utility/api";
import { Timezone } from "../../types/settings";
import { CircularProgress, Snackbar } from "@mui/material";

const Settings = () => {
  const [timeZones, setTimeZones] = useState<Timezone[]>([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
  };

  const fetchTimeZone = async () => {
    try {
      const result = await get<Timezone[]>("/gospel/timezones/list");
      setTimeZones(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTimeZone();
  }, []);

  const options = timeZones.map((item) => ({
    value: item.id,
    label: item.displayString,
  }));

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!firstName && !lastName && !email && !selectedValue) {
      setError("Please fill at least one field.");
      return;
    }
    if (email && !validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    setError("");
    setEmailError("");

    const params = new URLSearchParams();
    if (firstName) params.append("fname", firstName);
    if (lastName) params.append("lname", lastName);
    if (email) params.append("email", email);
    if (selectedValue) params.append("tz", selectedValue);

    try {
      setLoading(true);
      await get(`/gospel/user/update?${params.toString()}`);
      setSuccessOpen(true);
      setLoading(false);
    } catch (error) {
      console.error("Error updating user:", error);
      setLoading(false);
      setError("Failed to update user.");
    }
  };

  return (
    <div className="p-5">
      <div className="py-1">
        <GospelLabel
          label={"First Name"}
          className="text-xs text-gray-400 mb-1 block pt-1"
        />
        <GospelInput
          type="text"
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
          placeholder="Enter First Name"
          className="block w-full max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
        />
      </div>
      <div className="py-2">
        <GospelLabel
          label={"Last Name"}
          className="text-xs text-gray-400 mb-1 block pt-1"
        />
        <GospelInput
          type="text"
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
          placeholder="Enter Last Name"
          className="block w-full max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
        />
      </div>
      <div className="py-2">
        <GospelLabel
          label={"Email"}
          className="text-xs text-gray-400 mb-1 block pt-1"
        />
        <GospelInput
          type="text"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="Enter Email"
          className="block w-full max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
        />
      </div>
      {emailError && (
        <div className="text-gospel-error-red mb-2 text-sm bg-gospel-red-100 px-3 mt-2 py-2 rounded max-w-[450px]">
          {emailError}
        </div>
      )}
      <p className="text-sm py-5 font-normal">
        Please{" "}
        <a href="/forgotpassword" className="text-gospel-purple">
          click here
        </a>{" "}
        if you would like to reset your password.
      </p>
      <div>
        <GospelLabel
          label={"Timezone"}
          className="text-xs text-gray-400 mb-1 block pt-1"
        />
        <GospelSelectBox
          options={options}
          value={selectedValue}
          onChange={handleSelectChange}
          containerClass="relative max-w-[450px]"
          className="w-full max-w-[450px] h-10"
          placeholder="Select"
        />
      </div>
      {error && (
        <div className="text-gospel-error-red mb-2 text-sm bg-gospel-red-100 px-3 mt-2 py-2 rounded max-w-[450px]">
          {error}
        </div>
      )}
      <div className="pt-8 pb-2">
        <GospelButton
          onClick={handleSubmit}
          label={
            loading ? (
              <CircularProgress
                className="self-center justify-center text-gospel-purple-200 font-bold"
                size={16}
              />
            ) : (
              "Save Changes"
            )
          }
          className="bg-white px-4 py-2.5 w-36 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer inline-block font-medium hover:text-gray-700 hover:border-gray-400"
        />
      </div>
      <Snackbar
        open={successOpen}
        autoHideDuration={10000}
        onClose={() => setSuccessOpen(false)}
        message="User deatails updated successfully."
        ContentProps={{
          sx: {
            background: "#9D63B0",
          },
        }}
      />
    </div>
  );
};

export default Settings;
