export interface WatchlistItem {
  created: number;
  creationDate: string;
  deleted: number;
  gospelUserId: number;
  hidden: boolean;
  id: number;
  isOwner: boolean;
  lastSeen: number;
  lastUpdated: number;
  lastUpdatedBy: number;
  ownerName: string;
  shared: boolean;
  size: number;
  title: string;
}

interface NetworkId {
  channelInfoId: number;
  networkId: number;
}

export interface ChannelList {
  id: number;
  pageSlug: string;
  pageTitle: string;
  dateAdded: string;
  gospelURL: string;
  channelId: string;
  channelURL: string;
  channelCreationDate: string;
  category: string;
  country: string;
  channelImage: string;
  bannerImage: string;
  totalViews: number;
  dailyViews: number;
  weeklyViews: number;
  weeklyViewsChange: string;
  monthlyViews: number;
  monthlyViewsChange: string;
  totalSubs: number;
  dailySubs: number;
  weeklySubs: number;
  weeklySubsChange: string;
  monthlySubs: number;
  monthlySubsChange: string;
  channelDescription: string;
  networkId: number;
  totalVideos: number;
  nonLibraryViews30: number;
  libraryViews: number;
  ldpv30: number;
  ldpv90: number;
  dpv30: number;
  dpv90: number;
  vpv30: number;
  vpv30PercentChange: string;
  a7v30: string;
  a7v30PercentChange: string;
  v7day: string;
  v7dayPercentChange: string;
  a30v90: string;
  a30v90PercentChange: string;
  v30day: string;
  v30dayPercentChange: string;
  minv30: string;
  minv30PercentChange: string;
  minv90: string;
  minv90PercentChange: string;
  epv30: number;
  epv30PercentChange: string;
  a7e30: string;
  a7e30PercentChange: string;
  e7day: string;
  e7dayPercentChange: string;
  epmv30: string;
  epmv30PercentChange: string;
  cpv30: number;
  cpv30PercentChange: string;
  a7c30: string;
  a7c30PercentChange: string;
  cpmv30: string;
  cpmv30PercentChange: string;
  lpv30: number;
  lpv30PercentChange: string;
  a7l30: string;
  a7l30PercentChange: string;
  lpmv30: string;
  lpmv30PercentChange: string;
  dur30: number;
  dur30PercentChange: string;
  subs1k: string;
  subs1kPercentChange: string;
  spmv30: string;
  spmv30PercentChange: string;
  ldpv30PercentChange: string;
  dpv30PercentChange: string;
  ldr30: number;
  ldr30PercentChange: string;
  a7ld30: number;
  a7ld30PercentChange: string;
  a7d30: number;
  a7d30PercentChange: string;
  ldpmv30: number;
  ldpmv30PercentChange: string;
  dpmv30: number;
  dpmv30PercentChange: string;
  ldrpmv30: string;
  ldrpmv30PercentChange: string;
  ldr7: number;
  ldr7PercentChange: string;
  vpv90: number;
  vpv90PercentChange: string;
  a7v90: string;
  a7v90PercentChange: string;
  epv90: number;
  epv90PercentChange: string;
  a7e90: string;
  a7e90PercentChange: string;
  epmv90: string;
  epmv90PercentChange: string;
  cpv90: number;
  cpv90PercentChange: string;
  a7c90: string;
  a7c90PercentChange: string;
  cpmv90: string;
  lpv90: number;
  lpv90PercentChange: string;
  a7l90: string;
  a7l90PercentChange: string;
  lpmv90: string;
  lpmv90PercentChange: string;
  dur90: number;
  dur90PercentChange: string;
  ldpv90PercentChange: string;
  dpv90PercentChange: string;
  ldr90: number;
  ldr90PercentChange: string;
  a7ld90: number;
  a7ld90PercentChange: string;
  a7d90: number;
  a7d90PercentChange: string;
  ldpmv90: number;
  ldpmv90PercentChange: string;
  dpmv90: number;
  dpmv90PercentChange: string;
  latestUpload: string;
  latestUploadDate: string;
  latestUploadTime: string;
  latestUploadVideoId: string;
  latestUploadURL: string;
  latestUploadTitle: string;
  latestUploadViewCount: number;
  latestUploadDuration: number;
  latestUploadThumbnailURL: string;
  weeklyUploads: number;
  weeklyUploadsPercentChange: string;
  monthlyUploads: number;
  monthlyUploadsPercentChange: string;
}

export interface WatchlistDeatils {
  id: number;
  gospelUserId: number;
  shared: boolean;
  hidden: boolean;
  created: number;
  deleted: number;
  lastSeen: number;
  lastUpdated: number;
  lastUpdatedBy: number;
  title: string;
  size: number;
  isOwner: boolean;
  lastUpdatedInfo: string;
  items: ChannelList[];
  pages: number;
  totalSize: number;
}

export const watchlistStats = [
  { label: "All", value: "all" },
  { label: "Total Views", value: "totalViews" },
  { label: "Total Subs", value: "totalSubs" },
  { label: "Daily Views", value: "dailyViews" },
  { label: "Daily Subs", value: "dailySubs" },
  { label: "7 Day Views", value: "weeklyViews" },
  { label: "7 Day Subs", value: "weeklySubs" },
  { label: "30 Day Views", value: "monthlyViews" },
  { label: "30 Day Subs", value: "monthlySubs" },
  { label: "VPV90", value: "vpv90" },
  { label: "VPV30", value: "vpv30" },
  { label: "MinV30", value: "minv30" },
  { label: "V7Day", value: "v7day" },
  { label: "SPMV30", value: "spmv30" },
  { label: "CLDPV30", value: "epv30" },
  { label: "CLD7Day", value: "e7day" },
  { label: "CLDPMV30", value: "epmv30" },
  { label: "Uploads in the Last 7 Days", value: "weeklyUploads" },
  { label: "Uploads in the Last 30 Days", value: "monthlyUploads" },
];

