import { useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import { get } from "../../utility/api";
import GospelButton from "../../common/GospelButton/GospelButton";
import { useNavigate, useParams } from "react-router-dom";
import GospelLabel from "../../common/GospelLabel/GospelLabel";
import {
  Avatar,
  Checkbox,
  ConfigProvider,
  Dropdown,
  Modal,
  Select,
  Space,
} from "antd";
import { CircularProgress } from "@mui/material";
import { Cancel, Close, Dehaze, RemoveRedEyeSharp } from "@mui/icons-material";
import type { MenuProps } from "antd";
import {
  ChannelList,
  WatchlistDeatils,
  watchlistStats,
} from "../../types/watchlist";
import Lottie from "react-lottie";
import * as animationData from "../../assets/animations/sucess.json";
import { formatNumberWithCommas } from "../../utility/common";

function WatchlistDetails() {
  const { id } = useParams();
  const [watchList, setWatchList] = useState<WatchlistDeatils>(
    {} as WatchlistDeatils
  );
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [showChannelInfo, setShowChannelInfo] = useState<boolean>(false);
  const [createReportSuccessModal, setCreateReportSuccessModal] =
    useState<boolean>(false);

  const fieldMapping: Record<string, keyof ChannelList> = {
    totalViews: "totalViews",
    totalSubs: "totalSubs",
    dailyViews: "dailyViews",
    dailySubs: "dailySubs",
    weeklyViews: "weeklyViews",
    weeklySubs: "weeklySubs",
    monthlyViews: "monthlyViews",
    monthlySubs: "monthlySubs",
    vpv30: "vpv30",
    vpv90: "vpv90",
    minv30: "minv30",
    v7day: "v7day",
    spmv30: "spmv30",
    cldpv30: "epv30",
    epv30PercentChange: "epv30PercentChange",
    cld7day: "e7day",
    e7dayPercentChange: "e7dayPercentChange",
    cldpmv30: "epmv30",
    epmv30PercentChange: "epmv30PercentChange",
    uploads7Days: "weeklyUploads",
    uploads30Days: "monthlyUploads",
  };
  const navigate = useNavigate();

  const defaultAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <button onClick={() => {}}>Compare Channels</button>,
    },
    {
      key: "2",
      label: <button onClick={() => {}}>Email Me This Watchlist</button>,
    },
    {
      key: "3",
      label: (
        <button onClick={() => {}}>Create a Report for This Watchlist</button>
      ),
    },
  ];

  const handleChange = (selectedValues: string[]) => {
    // Handle "Select All" logic
    if (selectedValues.includes("all")) {
      if (selectedOptions.includes("all")) {
        // Deselect all if "All" is currently selected
        setSelectedOptions([]);
      } else {
        // Select all options except "All" itself
        setSelectedOptions(
          watchlistStats
            .map((option) => option.value)
            .filter((value) => value !== "all")
        );
      }
    } else {
      // Handle normal selection
      setSelectedOptions(selectedValues.filter((value) => value !== "all"));
    }
  };

  const toggleOption = (value: string) => {
    if (value === "all") {
      if (selectedOptions.length === watchlistStats.length - 1) {
        // If "All" is currently selecting everything, deselect all
        setSelectedOptions([]);
      } else {
        // Select all options except "All" itself
        setSelectedOptions(
          watchlistStats
            .map((option) => option.value)
            .filter((v) => v !== "all")
        );
      }
    } else {
      // Handle individual selection/deselection
      const newSelection = selectedOptions.includes(value)
        ? selectedOptions.filter((v) => v !== value)
        : [...selectedOptions.filter((v) => v !== "all"), value]; // Remove "all" if another option is selected

      setSelectedOptions(newSelection);
    }
  };

  const renderTableColumns = () => {
    // Map selected options to fieldMapping using `watchlistStats` labels as headers
    const selectedFields = selectedOptions.includes("all")
      ? Object.keys(fieldMapping)
      : selectedOptions;

    return selectedFields
      .filter((field) => fieldMapping[field])
      .map((field) => {
        const label =
          watchlistStats.find((stat) => stat.value === field)?.label || field;
        return {
          header: label,
          field: fieldMapping[field],
        };
      });
  };

  const toggleShowChannelInfo = () => {
    setShowChannelInfo(!showChannelInfo);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await get<WatchlistDeatils>(
          `/gospel/watchlist/get?id=${id}`
        );
        if (result) {
          setWatchList(result);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="overflow-hidden flex flex-col min-h-screen">
      <Header />
      <div className="flex items-center justify-center mt-[70px] md:mt-[70px] md:mx-8 xs:mx-2">
        {/* Adjust margin-top to match the header height */}
        <div className="text-center py-4 md:mx-auto xs:mx-2 overflow-y-auto mt-5 w-full flex items-center flex-col">
          {/* max-w-screen-md sets maximum width for content, overflow-y-auto enables scrolling */}
          <h1 className="text-2xl font-semibold md:text-2xl lg:text-2xl text-gospel-gray-300">
            {watchList.title}
          </h1>

          <div className="text-center p-4  w-full bg-white rounded-md shadow-md mt-8 ">
            <div className="flex justify-end px-4">
              <ConfigProvider
                theme={{
                  token: {
                    fontFamily: "Montserrat, sans-serif",
                    controlItemBgHover: "#9D63B0",
                    fontSize: 13,
                  },
                }}
              >
                <Dropdown
                  menu={{ items }}
                  placement="bottomRight"
                  arrow={{ pointAtCenter: true }}
                >
                  <Dehaze />
                </Dropdown>
              </ConfigProvider>
            </div>
            <div className="flex flex-col justify-center items-center gap-1 mb-4">
              <GospelLabel
                label={"Stats:"}
                className="text-sm text-gray-400 mb-1 block pt-1"
              />
              <ConfigProvider
                theme={{
                  components: {
                    Select: {
                      colorTextPlaceholder: "#353b3d",
                      colorTextQuaternary: "#353b3d",
                    },
                  },
                  token: {
                    fontFamily: "Montserrat, sans-serif",
                    colorPrimary: "#9D63B0",
                  },
                }}
              >
                <Select
                  mode="multiple"
                  style={{ width: "15%" }}
                  placeholder="Select options"
                  maxTagCount={1}
                  value={selectedOptions}
                  onChange={handleChange}
                  options={watchlistStats}
                  optionLabelProp="label"
                  removeIcon
                  dropdownRender={() => (
                    <div className="flex flex-col h-52 overflow-y-scroll">
                      {watchlistStats.map((option) => (
                        <Space key={option.value} style={{ padding: "8px" }}>
                          <Checkbox
                            checked={
                              option.value === "all"
                                ? selectedOptions.length ===
                                  watchlistStats.length - 1
                                : selectedOptions.includes(option.value)
                            }
                            onChange={() => toggleOption(option.value)}
                          />
                          {option.label}
                        </Space>
                      ))}
                    </div>
                  )}
                />
              </ConfigProvider>
            </div>
          </div>

          <div className="w-full my-6">
            <div className="flex flex-col py-8">
              {loading ? (
                <div className="p-8 flex items-center justify-center ">
                  <CircularProgress
                    className="text-gospel-gray-300 font-bold z-10"
                    size={30}
                  />
                </div>
              ) : (
                watchList.items &&
                watchList.items.length > 0 && (
                  <div className="overflow-auto">
                    <table className="min-w-full shadow-md divide-y divide-gray-200 ">
                      {/* Table Head */}
                      <thead className="bg-transparent">
                        <tr className="text-base text-gospel-gray-400">
                          <th className="font-medium text-left">
                            <span className="p-12"></span>
                          </th>
                          <th className="px-4 py-4 font-medium text-left">
                            Country
                          </th>
                          <th className="px-4 py-4 font-medium text-left">
                            Title
                          </th>
                          {renderTableColumns().map(({ header }) => (
                            <th
                              key={header}
                              className="px-4 py-4 font-medium text-left"
                            >
                              {header}
                            </th>
                          ))}
                          <th className="px-4 py-4 font-medium text-left">
                            
                          </th>
                        </tr>
                      </thead>

                      {/* Table Body */}
                      <tbody>
                        {watchList.items
                          .slice(
                            0,
                            showChannelInfo ? watchList.items.length : 10
                          )
                          .map((item) => (
                            <tr
                              key={item.channelId}
                              className="bg-white hover:bg-gray-100 border-b border-gray-200"
                            >
                              {/* Thumbnail */}
                              <td className="px-4 py-4 text-left">
                                <a
                                  href="/"
                                  className="text-gospel-purple no-underline w-12 h-12"
                                >
                                  <img
                                    src={item.channelImage}
                                    alt="channel-logo"
                                    className="w-12 h-12 rounded-full cursor-pointer"
                                  />
                                </a>
                              </td>

                              {/* Country */}
                              <td className="px-4 py-4 text-left">
                                <div className="flex items-center gap-2">
                                  <Avatar
                                    src={
                                      <img
                                        alt={item.country}
                                        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${item?.country.toUpperCase() || ""}.svg`}
                                        className="rounded-full"
                                      />
                                    }
                                  />
                                  <p className="text-sm text-gospel-gray-300">
                                    {item.country}
                                  </p>
                                </div>
                              </td>

                              {/* Title */}
                              <td
                                className="px-4 py-4 text-left text-gospel-purple text-base cursor-pointer"
                                onClick={() =>
                                  navigate(`/channel/${item.pageSlug}`)
                                }
                              >
                                {item.pageTitle}
                              </td>

                              {/* Dynamic Columns */}
                              {renderTableColumns().map(({ field }) => (
                                <td
                                  key={field}
                                  className="px-4 py-4 text-left text-gospel-gray-300"
                                >
                                  {formatNumberWithCommas(
                                    item[field as keyof ChannelList]
                                  )}
                                </td>
                              ))}

                              {/* Action Button */}
                              <td className="p-4 text-center">
                                <button className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-1">
                                  <Close
                                    className="text-white block"
                                    fontSize="small"
                                  />
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: 18,
              titleColor: "#353b3d",
              fontFamily: "Montserrat, sans-serif",
            },
          },
        }}
      >
        <Modal
          title="Your Report is on the Way! 📬"
          open={createReportSuccessModal}
          footer={null}
          closeIcon={
            <Cancel
              htmlColor="#000"
              fontSize="medium"
              onClick={() => setCreateReportSuccessModal(false)}
            />
          }
          className="text-center"
        >
          <Lottie options={defaultAnimationOptions} height={250} width={250} />
          <div className="mt-4 mb-6">
            <p className="text-gospel-purple">
              Your report is being generated and will be sent to your email
              shortly!!
            </p>
            <p className="text-gospel-purple ">
              Please check your inbox in a few minutes.
            </p>
          </div>
        </Modal>
      </ConfigProvider>
      <Footer />
    </div>
  );
}

export default WatchlistDetails;
