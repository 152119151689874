import React from "react";

interface GospelInputProps {
  type?: string;
  placeholder: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
}

const GospelInput: React.FC<GospelInputProps> = ({
  type,
  placeholder,
  className = "",
  onChange,
  value,
  onKeyDown
}) => {
  return (
    <input
      className={className}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onKeyDown={onKeyDown}
    />
  );
};

export default GospelInput;
