import React, { useCallback, useEffect, useState } from "react";
import GospelLabel from "../../common/GospelLabel/GospelLabel";
import GospelInput from "../../common/GospelInput/GospelInput";
import GospelSelectBox from "../../common/GospelSelect/GospelSelect";
import GospelButton from "../../common/GospelButton/GospelButton";
import { get } from "../../utility/api";
import { WatchlistItem } from "../../types/watchlist";
import { CircularProgress, Snackbar } from "@mui/material";

const Watchlist = () => {
  const [selectedValue, setSelectedValue] = useState("Name");
  const [isRenameVisible, setIsRenameVisible] = useState<{ id: number | null }>(
    { id: null },
  );
  const [isImportVisible, setIsImportVisible] = useState<{ id: number | null }>(
    { id: null },
  );
  const [watchList, setWatchList] = useState<WatchlistItem[]>([]);
  const [newWatchlistName, setNewWatchlistName] = useState<string>("");
  const [renameInputValue, setRenameInputValue] = useState<string>("");
  const [watchlistNameError, setWatchlistNameError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [createWatchlistLoader, setCreateWatchlistLoader] =
    useState<boolean>(false);

  const handleRenameClick = useCallback(
    (id: number) => {
      setIsRenameVisible({ id });
      setIsImportVisible({ id: null });
      // Set initial value for rename input based on current item title
      const currentItem = watchList.find((item) => item.id === id);
      if (currentItem) {
        setRenameInputValue(currentItem.title);
      }
    },
    [watchList],
  );

  const handleDeleteClick = useCallback(
    async (id: number) => {
      const updatedList = watchList.filter((item) => item.id !== id);
      setWatchList(updatedList);
      try {
        await get(`/gospel/watchlist/delete?id=${id}`);
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    },
    [watchList],
  );

  const handleImportClick = useCallback((id: number) => {
    setIsImportVisible({ id });
    setIsRenameVisible({ id: null });
  }, []);

  const handleSelectChange = useCallback((value: string) => {
    setSelectedValue(value);
  }, []);

  const options = ["Name", "Creation Date"].map((item) => ({
    value: item,
    label: item,
  }));

  const createNewWatchlist = async () => {
    try {
      if (newWatchlistName.trim() === "") {
        setWatchlistNameError("Please enter a watchlist name.");
        return;
      }
      setCreateWatchlistLoader(true);
      await get(`/gospel/watchlist/create?title=${newWatchlistName}`);
      fetchWatchlist(false);
      setNewWatchlistName("");
      setWatchlistNameError("");
      setCreateWatchlistLoader(false);
      setSuccessOpen(true);
    } catch (error) {
      console.error("Error creating new watchlist:", error);
      setCreateWatchlistLoader(false);
    }
  };

  const renameWatchlist = async (id: number, newName: string) => {
    try {
      await get(`/gospel/watchlist/update?id=${id}&title=${newName}`);

      // Update the watchList state immediately with the new name
      const updatedList = watchList.map((item) =>
        item.id === id ? { ...item, title: newName } : item,
      );
      setWatchList(updatedList);

      setIsRenameVisible({ id: null }); // Close rename input after successful rename
    } catch (error) {
      console.error("Error renaming watchlist:", error);
    }
  };

  const fetchWatchlist = async (isFirstLoad: boolean) => {
    try {
      isFirstLoad && setLoading(true);
      const result = await get<WatchlistItem[]>("/gospel/watchlist/list");
      if (result) {
        setWatchList(result);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWatchlist(true);
  }, []);

  useEffect(() => {
    const compareItems = (a: WatchlistItem, b: WatchlistItem) => {
      if (selectedValue === "Name") {
        return a.title.localeCompare(b.title);
      } else if (selectedValue === "Creation Date") {
        return a.created - b.created;
      }
      return 0;
    };
    const sortedList = [...watchList].sort(compareItems);
    setWatchList(sortedList);
  }, [selectedValue]);

  return (
    <div className="p-5 flex flex-col">
      {loading ? (
        <CircularProgress
          className="self-center justify-center text-gospel-purple-200 font-bold"
          size={30}
        />
      ) : watchList.length === 0 ? (
        <p className="text-sm font-normal text-gospel-gray-300">
          You currently have no watchlists. Do a search or visit a channel page
          to create a watchlist and add channels to it.
        </p>
      ) : (
        <>
          <div className="py-1 border-b border-gospel-gray-400 ">
            <GospelInput
              type="text"
              onChange={(e) => {
                setNewWatchlistName(e.target.value);
                setWatchlistNameError(""); // Clear error when input changes
              }}
              value={newWatchlistName}
              placeholder="Create new list"
              className="block w-full max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
            />
            {watchlistNameError && (
              <div className="text-gospel-error-red mb-2 text-sm bg-gospel-red-100 px-3 mt-2 py-2 rounded max-w-[450px]">
                {watchlistNameError}
              </div>
            )}
            <GospelButton
              onClick={createNewWatchlist}
              label={
                createWatchlistLoader ? (
                  <CircularProgress
                    className="self-center justify-center text-gospel-purple-200 font-bold"
                    size={16}
                  />
                ) : (
                  "Create Watchlist"
                )
              }
              disabled={!newWatchlistName.trim()}
              className="bg-white w-40 py-2.5 mt-2 mb-8 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer inline-block font-medium hover:text-gray-700 hover:border-gray-400"
            />
          </div>
          <div className="py-6 pb-8">
            <GospelLabel
              label={"Sort by:"}
              className="text-xs text-gray-400 mb-1 block pt-1"
            ></GospelLabel>
            <GospelSelectBox
              options={options}
              value={selectedValue}
              onChange={handleSelectChange}
              containerClass="relative max-w-[450px]"
              className="w-full max-w-[450px] h-10"
              placeholder="Select"
            />
          </div>

          <div className="h-auto">
            {watchList.map((item) => (
              <React.Fragment key={item.id}>
                <div className="mt-4 mb-2 border-t border-gray-300 relative"></div>
                <div className="block">
                  <a
                    className="text-gospel-purple cursor-pointer transition-colors duration-500 hover:text-black"
                    href={`/account/watchlists/list/${item.id}`}
                  >
                    {item.title} ({item.size})
                  </a>
                </div>

                {isRenameVisible.id === item.id && (
                  <div className="mb-2 flex-row flex">
                    <GospelInput
                      type="text"
                      onChange={(e) => setRenameInputValue(e.target.value)}
                      value={renameInputValue}
                      placeholder="Enter New Name"
                      className="block w-3/4 max-w-[250px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                    />

                    <GospelButton
                      className="text-white h-[40px] px-5 rounded-md text-sm font-medium transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple ml-3"
                      label="Save"
                      onClick={() => renameWatchlist(item.id, renameInputValue)}
                    />
                    <GospelButton
                      className="bg-white px-2 py-2.5 ml-2 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer inline-block font-medium hover:text-gray-700 hover:border-gray-400"
                      label="Cancel"
                      onClick={() => setIsRenameVisible({ id: null })}
                    />
                  </div>
                )}

                {isImportVisible.id === item.id && (
                  <div className="mt-8 flex flex-row">
                    <form
                      id="importForm"
                      method="POST"
                      encType="multipart/form-data"
                    >
                      <input
                        className="rounded-md pr-3 py-1 text-sm"
                        name="file"
                        type="file"
                        accept=".csv"
                      />
                      <GospelButton
                        className="text-white h-[40px] px-5 rounded-md text-sm font-medium transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple ml-3"
                        label="Save"
                        onClick={() => {}}
                      />
                    </form>
                    <GospelButton
                      className="bg-white px-2 py-2.5 ml-2 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer inline-block font-medium hover:text-gray-700 hover:border-gray-400"
                      label="Cancel"
                      onClick={() => setIsImportVisible({ id: null })}
                    />
                  </div>
                )}

                <span
                  className="mr-2.5 text-xs cursor-pointer text-gospel-gray-400 transition-colors duration-500 ease"
                  onClick={() => handleRenameClick(item.id)}
                >
                  Rename
                </span>
                <span
                  className="mr-2.5 text-xs cursor-pointer text-gospel-gray-400 transition-colors duration-500 ease"
                  onClick={() => handleDeleteClick(item.id)}
                >
                  Delete
                </span>
                <span
                  className="mr-2.5 text-xs cursor-pointer text-gospel-gray-400 transition-colors duration-500 ease"
                  onClick={() => handleImportClick(item.id)}
                >
                  Import
                </span>
                <span className="text-xs text-gospel-gray-400 transition-colors duration-500 ease">
                  |&nbsp; &nbsp;Created {item.creationDate}
                </span>
              </React.Fragment>
            ))}
          </div>
          <Snackbar
            open={successOpen}
            autoHideDuration={10000}
            onClose={() => setSuccessOpen(false)}
            message="Watchlist added successfully."
            ContentProps={{
              sx: {
                background: "#9D63B0",
              },
            }}
          />
        </>
      )}
    </div>
  );
};

export default Watchlist;
