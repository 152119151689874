import dayjs from "dayjs";
import { ChannelData } from "../../types/channel";
import moment from "moment";

const last30DaysStart = moment()
  .clone()
  .subtract(30, "days")
  .format("MM/DD/YYYY");
const last30DaysEnd = moment().format("MM/DD/YYYY");
const last7DaysStart = moment()
  .clone()
  .subtract(7, "days")
  .format("MM/DD/YYYY");
const last7DaysEnd = moment().format("MM/DD/YYYY");

const demographicData = {
  genderDistribution: {
    male: "55%",
    female: "45%",
  },
  audienceLocation: [
    { country: "United States", percentage: "70%" },
    { country: "India", percentage: "75%" },
    { country: "United Kingdom", percentage: "50%" },
    { country: "Canada", percentage: "68%" },
    { country: "Australia", percentage: "45%" },
  ],
  audienceAge: [
    { ageRange: "13-17", percentage: "20%" },
    { ageRange: "18-24", percentage: "30%" },
    { ageRange: "25-34", percentage: "45%" },
    { ageRange: "35-44", percentage: "50%" },
    { ageRange: "45-64", percentage: "65%" },
  ],
};

const channelChartTabs = [
  {
    key: 1,
    label: "Views",
    dataGraph: "views",
    dataColor: "#9D63B0",
    dataHide: "",
    keyName: "count",
  },
  {
    key: 2,
    label: "Subscribers",
    dataGraph: "subs",
    dataColor: "#7375db",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 3,
    label: "Uploads",
    dataGraph: "uploads",
    dataColor: "#64c0ea",
    dataHide: "hourly",
    keyName: "uploadCount",
  },
  {
    key: 4,
    label: "Shorts Uploads",
    dataGraph: "shorts",
    dataColor: "#f55a98",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 5,
    label: "Longs Uploads",
    dataGraph: "longs",
    dataColor: "#c05af5",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 6,
    label: "VPV90",
    dataGraph: "vpv90",
    dataColor: "#6edfd1",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 7,
    label: "Shorts VPV90",
    dataGraph: "shvpv90",
    dataColor: "#00d114",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 8,
    label: "Longs VPV90",
    dataGraph: "lgvpv90",
    dataColor: "#ff9415",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 9,
    label: "VPV30",
    dataGraph: "vpv30",
    dataColor: "#6edf83",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 10,
    label: "V30Day",
    dataGraph: "v30day",
    dataColor: "#f5bd5a",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 11,
    label: "V7Day",
    dataGraph: "v7day",
    dataColor: "#f55a5a",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 12,
    label: "CLPMV30",
    dataGraph: "cldpmv30",
    dataColor: "#516eff",
    dataHide: "hourly",
    keyName: "count",
  },
];

const generateViewData = (channelData: ChannelData) => {
  return [
    {
      title: "30 Day Views",
      key: "last30Days",
      info: `${last30DaysStart} - ${last30DaysEnd}`,
      percentChange: channelData.last30Days.viewsPercentChange,
      value: channelData.last30Days.views,
    },
    {
      title: "7 Day Views",
      key: "last7Days",
      info: `${last7DaysStart} - ${last7DaysEnd}`,
      percentChange: channelData.last7Days.viewsPercentChange,
      value: channelData.last7Days.views,
    },
    {
      title: "24 Hour Views",
      key: "last24Hours",
      percentChange: channelData.last24Hours.viewsPercentChange,
      value: channelData.last24Hours.views,
    },
    {
      title: "VPV90",
      key: "vpv90",
      description: "Views per Video in the last 90 days.",
      info: "The average views per video on all the videos uploaded to a channel in the last 90 days.",
      percentChange: channelData.vpv90PercentChange,
      value: channelData.vpv90,
      children: [
        {
          title: "SHVPV90",
          key: "shvpv90",
          description: "Views per Short in the last 90 days",
          info: "The average views per Short on Shorts uploaded to a channel in the last 90 days.",
          value: channelData.shvpv90,
        },
        {
          title: "LGVPV90",
          key: "lgvpv90",
          description: "Views per regular video in the last 90 days",
          info: "The average views per regular videos on all the regular videos uploaded to a channel in the last 90 days.",
          value: channelData.lgvpv90,
        },
      ],
    },
    {
      title: "VPV30",
      key: "vpv30",
      description: " Views per Video in the last 30 days.",
      info: "The average views per video on all the videos uploaded to a channel in the last 30 days.",
      percentChange: channelData.vpv30PercentChange,
      value: channelData.vpv30,
      children: [
        {
          title: "SHVPV30",
          key: "shvpv30",
          description: "Views per Short in the last 30 days",
          info: "The average views per Short on all  Shorts uploaded to a channel in the last 30 days.",
          value: channelData.shvpv30,
        },
        {
          title: "LGVPV30",
          key: "lgvpv30",
          description: "Views per regular video in the last 30 days",
          info: "The average views per regular videos on all the regular videos uploaded to a channel in the last 30 days.",
          value: channelData.lgvpv30,
        },
      ],
    },
    {
      title: "MinV30",
      key: "minv30",
      description: "Minimum views on a video in the last 30 days.",
      info: "The viewcount of the video with the least amount of views that’s been uploaded in the past 30 days and is at least 7 days old.",
      percentChange: channelData.minv30PercentChange,
      value: channelData.minv30,
      children: [
        {
          title: "SHMINV30",
          key: "shminv30",
          description: "Minimum views on a Short video in the last 30 days",
          info: "The viewcount of Shorts with the least amount of views that’s been uploaded in the past 30 days and is at least 7 days old.",
          value: channelData.shminv30,
        },
        {
          title: "LGMINV30",
          key: "lgminv30",
          description: "Minimum views on a regular video in the last 30 days",
          info: "The viewcount of regular videos with the least amount of views that’s been uploaded in the past 30 days and is at least 7 days old.",
          value: channelData.lgminv30,
        },
      ],
    },
    {
      title: "V7Day",
      key: "a7v30",
      description: "Views per video in first 7 days.",
      info: "The average first 7-day views per video of all the videos uploaded to a channel in the last 30 days.",
      percentChange: channelData.a7v30PercentChange,
      value: channelData.a7v30,
      children: [
        {
          title: "SH7V30",
          key: "sh7v30",
          description: "Views per Short video in first 7 days",
          info: "The average first 7-day views per Short video of all Shorts uploaded to a channel in the last 30 days.",
          value: channelData.sh7v30,
        },
        {
          title: "LG7V30",
          key: "lg7v30",
          description: "Views per regular video in first 7 days",
          info: "The average first 7-day views per regular video of all the regular videos uploaded to a channel in the last 30 days.",
          value: channelData.lg7v30,
        },
      ],
    },
  ];
};

const generateSubscriberData = (channelData: ChannelData) => {
  return [
    {
      title: "30 Day Subscribers",
      key: "last30Days",
      info: `${last30DaysStart} - ${last30DaysEnd}`,
      percentChange: channelData.last30Days.subsPercentChange,
      value: channelData.last30Days.subs,
    },
    {
      title: "7 Day Subscribers",
      key: "last7Days",
      info: `${last7DaysStart} - ${last7DaysEnd}`,
      percentChange: channelData.last7Days.subsPercentChange,
      value: channelData.last7Days.subs,
    },
    {
      title: "24 Hour Subscribers",
      key: "last24Hours",
      percentChange: channelData.last24Hours.subsPercentChange,
      value: channelData.last24Hours.subs,
    },
  ];
};

const generateCommentsData = (channelData: ChannelData) => {
  return [
    {
      title: "CLPV30",
      info: "The average number of Likes and Comments on all the videos uploaded to a channel in the last 30 days.",
      percentChange: channelData.epv30PercentChange,
      description: "Comments and likes per Video in the last 30 days.",
      value: channelData.epv30,
      children: [
        {
          title: "CPV30",
          key: "cpv30",
          info: "The average number of Comments on all the videos uploaded to a channel in the last 30 days.",
          description: "Comments per Video in the last 30 days",
          percentChange: channelData.cpv30PercentChange,
          value: channelData.cpv30,
        },
        {
          title: "LPV30",
          key: "lpv30",
          info: "The average number of Likes on all the videos uploaded to a channel in the last 30 days.",
          description: "Likes per video in the last 30 days",
          percentChange: channelData.lpv30PercentChange,
          value: channelData.lpv30,
        },
      ],
    },
    {
      title: "SHEPV30",
      info: "The average number of Likes and Comments on all Shorts uploaded to a channel in the last 30 days.",
      description: "Comments and likes per Short video in the last 30 days.",
      value: channelData.shepv30,
      children: [
        {
          title: "SHCPV30",
          key: "shcpv30",
          description: "Comments per Short video in the last 30 days",
          info: "The average number of Comments on all Shorts uploaded to a channel in the last 30 days.",
          value: channelData.shcpv30,
        },
        {
          title: "SHLPV30",
          key: "shlpv30",
          info: "The average number of Likes of all Shorts added to a channel in the last 30 days.",
          description: "Likes per Short video in the last 30 days",
          value: channelData.shlpv30,
        },
      ],
    },
    {
      title: "LGEPV30",
      description: "Comments and likes per regular video in the last 30 days.",
      info: "The average number of Likes and Comments on all the regular videos uploaded to a channel in the last 30 days.",
      value: channelData.lgepv30,
      children: [
        {
          title: "LGCPC30",
          key: "lgcpv30",
          description: "Comments per regular video in the last 30 days",
          info: "The average number of Comments on all the regular videos uploaded to a channel in the last 30 days.",
          value: channelData.lgcpv30,
        },
        {
          title: "LGLPV30",
          key: "lglpv30",
          description: "Likes per regular video in the last 30 days",
          info: "The average number of Likes of all the regular videos added to a channel in the last 30 days.",
          value: channelData.lglpv30,
        },
      ],
    },
    {
      title: "CLPMV30",
      description: "Comments and likes per thousand views in the last 30 days.",
      info: "The average number of Likes and Comments per thousand views on videos uploaded in the last 30 days.",
      percentChange: channelData.epmv30PercentChange,
      value: channelData.epmv30,
      children: [
        {
          title: "CPMV30",
          key: "cpmv30",
          description: "Comments per thousand views in the last 30 days",
          info: "The average number of Comments per thousand views on videos uploaded in the last 30 days.",
          percentChange: channelData.cpmv30PercentChange,
          value: channelData.cpmv30,
        },
        {
          title: "LPMV30",
          key: "lpmv30",
          description: "Likes per thousand views in the last 30 days",
          info: "The average number of Likes per thousand views on videos uploaded in the last 30 days.",
          percentChange: channelData.lpmv30PercentChange,
          value: channelData.lpmv30,
        },
        {
          title: "SHEPMV30",
          key: "shepmv30",
          description:
            "Short video likes per thousand views in the last 30 days",
          info: "The average number of Likes per thousand views on all Shorts uploaded in the last 30 days.",
          value: channelData.shepmv30,
        },
        {
          title: "LGEPMV30",
          key: "lgepmv30",
          description:
            "Regular video likes per thousand views in the last 30 days",
          info: "The average number of Likes per thousand views on regular videos uploaded in the last 30 days.",
          value: channelData.lgepmv30,
        },
      ],
    },
  ];
};

const generateUploadsData = (channelData: ChannelData) => {
  return [
    {
      key: 1,
      title: "Uploads in Last 30 Days",
      percentChange: channelData.last30Days.uploadsPercentChange,
      value: channelData.last30Days.uploads,
    },
    {
      key: 2,
      title: "Uploads in Last 7 Days",
      percentChange: channelData.last7Days.uploadsPercentChange,
      value: channelData.last7Days.uploads,
    },
  ];
};

export {
  channelChartTabs,
  generateCommentsData,
  generateSubscriberData,
  generateViewData,
  generateUploadsData,
  demographicData,
};
