import {
  Add,
  ArrowBack,
  Cancel,
  Close,
  PlusOne,
  PlusOneOutlined,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import GospelLabel from "../../common/GospelLabel/GospelLabel";
import GospelSelectBox from "../../common/GospelSelect/GospelSelect";
import GospelInput from "../../common/GospelInput/GospelInput";
import { Checkbox, ConfigProvider, Modal, Select, Space } from "antd";
import GospelButton from "../../common/GospelButton/GospelButton";
import {
  adminOptions,
  criterias,
  reportOptions,
  socialOptions,
} from "./ReportUtils";
import {
  Country,
  Language,
  Option,
  formatNumberWithCommas,
  removeCommasAndConvertToInt,
} from "../../utility/common";
import { get } from "../../utility/api";

interface CreateReportProps {
  setShowCreateReport: (value: boolean) => void;
}

const defaultFilters = {
  lifetimeViews: { min: "", max: "" },
  lifetimeSubscribers: { min: "", max: "" },
  countries: "",
  language: "",
  categories: "",
  adminCategories: "",
  viewsOverTime: { min: "", max: "" },
  subscribersOverTime: { min: "", max: "" },
  viewsPercentageChange: { min: "", max: "" },
  subscribersPercentageChange: { min: "", max: "" },
  v30Day: { min: "", max: "" },
  vpv30: { min: "", max: "" },
  vpv90: { min: "", max: "" },
  shvpv30: { min: "", max: "" },
  lgvpv30: { min: "", max: "" },
  shvpv90: { min: "", max: "" },
  lgvpv90: { min: "", max: "" },
  minv30: { min: "", max: "" },
  minv90: { min: "", max: "" },
  shortsMinv90: { min: "", max: "" },
  longsMinv90: { min: "", max: "" },
  cldpv30: { min: "", max: "" },
  cldpmv30: { min: "", max: "" },
  uploads: { min: "", max: "" },
  shortsUploads: { min: "", max: "" },
  longsUploads: { min: "", max: "" },
  shorts: "",
};

const CreateReport: React.FC<CreateReportProps> = ({ setShowCreateReport }) => {
  const [criteria, setCriteria] = useState("");
  const [selectedReport, setSelectedReport] =
    useState<string>("Channel Report");
  const [reportName, setReportName] = useState<string>("");
  const [reportRow, setReportRow] = useState<string>("100");
  const [social, setSocial] = useState<string>("");
  const [languageList, setLanguageList] = useState<Option[]>([]);
  const [countryList, setCountryList] = useState<Option[]>([]);
  const [filters, setFilters] = useState<any>(defaultFilters);
  const [reportModal, setReportModal] = useState<boolean>(false);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [criteriaOptions, setCriteriaOptions] = useState(criterias);

  const handleSocialChange = (value: string | string[]) => {
    setSocial(Array.isArray(value) ? value.join(",") : value); // Convert array to a comma-separated string
  };

  const toggleSocialOption = (value: string) => {
    const allSocialValues = socialOptions
      .map((option) => option.value)
      .filter((v) => v !== "all"); // Exclude "all"
    const current = social ? social.split(",") : []; // Current selections as an array

    if (value === "all") {
      if (current.length === allSocialValues.length) {
        // Deselect all if all options are selected
        setSocial("");
      } else {
        // Select all if "All" is clicked
        setSocial(allSocialValues.join(","));
      }
    } else {
      let newSelection: string[];
      if (current.includes(value)) {
        // Remove the value if already selected
        newSelection = current.filter((v) => v !== value);
      } else {
        // Add the value if not already selected
        newSelection = [...current, value];
      }

      if (newSelection.length === allSocialValues.length) {
        setSocial(allSocialValues.join(","));
      } else {
        // Remove "all" if not all options are selected
        newSelection = newSelection.filter((v) => v !== "all");
        setSocial(newSelection.join(","));
      }
    }
  };

  const handleFilterChange = (
    key: string,
    value: string,
    subKey?: "min" | "max"
  ) => {
    setFilters((prevFilters: any) => {
      if (subKey) {
        // For min-max fields
        return {
          ...prevFilters,
          [key]: {
            ...prevFilters[key],
            [subKey]: value,
          },
        };
      }
      // For non-object fields
      return { ...prevFilters, [key]: value };
    });
  };

  const handleAddCriteria = (value: string) => {
    setReportModal(false);
    setCriteria("");
    if (value && !selectedFields.includes(value)) {
      setSelectedFields((prev) => [...prev, value]);
    }
    // Remove the selected criteria from the available options
    setCriteriaOptions((prevOptions) =>
      prevOptions.filter((option) => option.value !== value)
    );
  };

  const handleRemoveCriteria = (field: string) => {
    // Remove the field from the selectedFields array
    setSelectedFields((prev) => prev.filter((item) => item !== field));

    // Add the removed criterion back to the dropdown options
    const removedCriteria = criterias.find((option) => option.value === field);

    if (removedCriteria) {
      setCriteriaOptions((prevOptions) => [...prevOptions, removedCriteria]);
    }

    // Remove the corresponding key from the filters
    setFilters((prevFilters: any) => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[field]; // Remove the filter key
      return updatedFilters;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch country and language data simultaneously
        const [countryResult, languageResult] = await Promise.all([
          get<Country[]>("gospel/countries/list"),
          get<Language[]>("gospel/languages/list"),
        ]);

        // Transform the country data into the desired format and add "All" option
        const transformedCountryOptions = [
          { value: "all", label: "All" },
          ...countryResult.map((country) => ({
            value: country.code,
            label: country.name,
          })),
        ];

        // Transform the language data into the desired format and add "All" option
        const transformedLanguageOptions = [
          { value: "all", label: "All" },
          ...languageResult.map((language) => ({
            value: language.code,
            label: language.name,
          })),
        ];

        // Update the state with the transformed data
        setCountryList(transformedCountryOptions);
        setLanguageList(transformedLanguageOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const renderField = (field: string) => {
    switch (field) {
      case "lifetimeViews":
        return (
          <div className="my-4 flex flex-col">
            <GospelLabel
              label={"Lifetime Views:"}
              className="text-xs text-gray-400 mb-1"
            />
            <div className="flex flex-row items-center gap-3">
              <div className="flex flex-col gap-1">
                <GospelInput
                  type="text"
                  value={formatNumberWithCommas(filters.lifetimeViews.min)}
                  onChange={(e) =>
                    handleFilterChange("lifetimeViews", e.target.value, "min")
                  }
                  placeholder="Min"
                  className="h-10 w-80 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <GospelLabel
                  label={"Min"}
                  className="text-xs text-gray-400 mb-1"
                />
              </div>
              <span className="text-gray-400">-</span>
              <div className="flex flex-col gap-1">
                <GospelInput
                  type="text"
                  value={formatNumberWithCommas(filters.lifetimeViews.max)}
                  onChange={(e) =>
                    handleFilterChange("lifetimeViews", e.target.value, "max")
                  }
                  placeholder="Max"
                  className="h-10 w-80 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <GospelLabel
                  label={"Max (leave blank for no max)"}
                  className="text-xs text-gray-400 mb-1"
                />
              </div>
              <button
                className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5 mb-6"
                onClick={() => handleRemoveCriteria(field)}
              >
                <Close className="text-white block" fontSize="small" />
              </button>
            </div>
          </div>
        );
      case "countries":
        return (
          <div className="my-4">
            <GospelLabel
              label={"Country:"}
              className="text-xs text-gray-400 mb-1"
            />
            <div className="flex flex-row items-center gap-3">
              <GospelSelectBox
                options={countryList}
                value={filters.countries}
                onChange={(value) => handleFilterChange("countries", value)}
                containerClass="relative w-1/2"
                className="h-10 w-full text-sm font-medium bg-white"
                placeholder="Select Country"
              />
              <button
                className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5"
                onClick={() => handleRemoveCriteria(field)}
              >
                <Close className="text-white block" fontSize="small" />
              </button>
            </div>
          </div>
        );

      case "language":
        return (
          <div className="my-4">
            <GospelLabel
              label={"Language:"}
              className="text-sm text-gray-400 mb-1 block pt-1"
            />
            <div className="flex flex-row items-center gap-3">
              <GospelSelectBox
                options={languageList}
                value={filters.language}
                onChange={(value) => handleFilterChange("language", value)}
                containerClass="relative w-1/2"
                className="h-10 w-full text-sm font-medium bg-white"
                placeholder="Select Language"
              />
              <button
                className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5"
                onClick={() => handleRemoveCriteria(field)}
              >
                <Close className="text-white block" fontSize="small" />
              </button>
            </div>
          </div>
        );
      case "adminCategories":
        return (
          <div className="my-4">
            <GospelLabel
              label={"Admin Categories::"}
              className="text-sm text-gray-400 mb-1 block pt-1"
            />
            <div className="flex flex-row items-center gap-3">
              <GospelSelectBox
                options={adminOptions}
                value={filters.adminCategories}
                onChange={(value) =>
                  handleFilterChange("adminCategories", value)
                }
                containerClass="relative w-1/2"
                className="h-10 w-full text-sm font-medium bg-white"
                placeholder="Select Admin Categories:"
              />
              <button
                className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5"
                onClick={() => handleRemoveCriteria(field)}
              >
                <Close className="text-white block" fontSize="small" />
              </button>
            </div>
          </div>
        );
      case "categories":
        return (
          <div className="my-4">
            <GospelLabel
              label={"Categories::"}
              className="text-sm text-gray-400 mb-1 block pt-1"
            />
            <div className="flex flex-row items-center gap-3">
              <GospelSelectBox
                options={adminOptions}
                value={filters.categories}
                onChange={(value) => handleFilterChange("categories", value)}
                containerClass="relative w-1/2"
                className="h-10 w-full text-sm font-medium bg-white"
                placeholder="Select Categories:"
              />
              <button
                className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5"
                onClick={() => handleRemoveCriteria(field)}
              >
                <Close className="text-white block" fontSize="small" />
              </button>
            </div>
          </div>
        );
      case "lifetimeSubscribers":
        return (
          <div className="my-4 flex flex-col">
            <GospelLabel
              label={"Lifetime Subscribers:"}
              className="text-xs text-gray-400 mb-1"
            />
            <div className="flex flex-row items-center gap-3">
              <div className="flex flex-col gap-1">
                <GospelInput
                  type="text"
                  value={formatNumberWithCommas(
                    filters.lifetimeSubscribers.min
                  )}
                  onChange={(e) =>
                    handleFilterChange(
                      "lifetimeSubscribers",
                      e.target.value,
                      "min"
                    )
                  }
                  placeholder="Min"
                  className="h-10 w-80 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <GospelLabel
                  label={"Min"}
                  className="text-xs text-gray-400 mb-1"
                />
              </div>
              <span className="text-gray-400">-</span>
              <div className="flex flex-col gap-1">
                <GospelInput
                  type="text"
                  value={formatNumberWithCommas(
                    filters.lifetimeSubscribers.max
                  )}
                  onChange={(e) =>
                    handleFilterChange(
                      "lifetimeSubscribers",
                      e.target.value,
                      "max"
                    )
                  }
                  placeholder="Max"
                  className="h-10 w-80 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <GospelLabel
                  label={"Max (leave blank for no max)"}
                  className="text-xs text-gray-400 mb-1"
                />
              </div>
              <button
                className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5 mb-6"
                onClick={() => handleRemoveCriteria(field)}
              >
                <Close className="text-white block" fontSize="small" />
              </button>
            </div>
          </div>
        );

      case "madeForKids":
        return (
          <div className="my-4">
            <GospelLabel
              label={"Made for Kids:"}
              className="text-sm text-gray-400 mb-1 block pt-1"
            />
            <GospelSelectBox
              options={[
                { label: "Include", value: "include" },
                { label: "Exclude", value: "exclude" },
                { label: "Only", value: "only" },
              ]}
              value={filters.madeForKids}
              onChange={(value) => handleFilterChange("madeForKids", value)}
              containerClass="relative max-w-[450px]"
              className="w-52 h-10 text-sm font-medium bg-white text-left"
              placeholder="Select"
            />
          </div>
        );
      case "shorts":
        return (
          <div className="my-4">
            <GospelLabel
              label={"Shorts:"}
              className="text-sm text-gray-400 mb-1 block pt-1"
            />
            <GospelSelectBox
              options={[
                { label: "Include", value: "include" },
                { label: "Exclude", value: "exclude" },
                { label: "Only", value: "only" },
              ]}
              value={filters.shorts}
              onChange={(value) => handleFilterChange("shorts", value)}
              containerClass="relative max-w-[450px]"
              className="w-52 h-10 text-sm font-medium bg-white text-left"
              placeholder="Select"
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="p-5">
      <div className="border-b border-gray-200 pb-6 my-4">
        <button
          className="text-purple-600 flex items-center gap-1"
          onClick={() => setShowCreateReport(false)}
        >
          <ArrowBack htmlColor="#9d63b0"></ArrowBack>
          <span className="text-gospel-purple text-sm hover:text-black">
            Back to Reports
          </span>
        </button>
      </div>

      <div>
        <div className="relative w-full my-4">
          <GospelLabel
            label={"Report type"}
            className="text-xs text-gray-400 mb-1 block pt-1"
          ></GospelLabel>
          <GospelSelectBox
            options={reportOptions}
            value={selectedReport}
            onChange={(value: string) => setSelectedReport(value)}
            containerClass="relative max-w-[450px]"
            className="w-full max-w-[450px] h-10"
            placeholder="Select"
          />
        </div>
        <div className="relative w-full my-4">
          <GospelLabel
            label={"Report name"}
            className="text-xs text-gray-400 mb-1 block pt-1"
          ></GospelLabel>

          <GospelInput
            type="text"
            onChange={(e) => {
              setReportName(e.target.value);
            }}
            value={reportName}
            placeholder="Enter Report Name"
            className="block w-full max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
          />
        </div>

        <p className="my-4 text-sm font-normal ">
          Generate a report on channels with the following criteria:
        </p>

        {/* Dynamic field */}

        <div>
          {selectedFields.map((field) => (
            <div key={field}>{renderField(field)}</div>
          ))}
        </div>

        {criteriaOptions.length > 0 && (
          <span
            onClick={() => {
              setReportModal(true);
            }}
          >
            <GospelButton
              className="text-white rounded-full p-1 transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple"
              label={<Add fontSize={"medium"}></Add>}
              onClick={() => {}}
            />
            <span className="text-gospel-purple text-sm hover:text-black ml-2 cursor-pointer">
              Add Criteria
            </span>
          </span>
        )}
        <div className="my-4">
          <GospelLabel
            label={"How many rows do you want returned in this report?"}
            className="text-xs text-gray-400 mb-1 block pt-1"
          ></GospelLabel>

          <GospelInput
            type="number"
            onChange={(e) => {
              setReportRow(e.target.value);
            }}
            value={reportRow}
            placeholder=""
            className="block w-full max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
          />
          <p className="text-xs text-gray-400">Your max per report is 100</p>
        </div>
        <div className="my-4">
          <GospelLabel
            label={"Socials:"}
            className="text-sm text-gray-400 mb-1 block pt-1"
          />
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  colorTextPlaceholder: "#353b3d",
                  colorTextQuaternary: "#353b3d",
                },
              },
              token: {
                fontFamily: "Montserrat, sans-serif",
                colorPrimary: "#9D63B0",
              },
            }}
          >
            <Select
              mode="multiple"
              className="w-full max-w-[450px]"
              style={{ height: 40 }}
              placeholder="Select"
              maxTagCount={1}
              value={social ? social.split(",") : []}
              onChange={(value) => handleSocialChange(value)}
              options={socialOptions}
              optionLabelProp="label"
              dropdownRender={() => (
                <div className="flex flex-col h-52 overflow-y-scroll">
                  <Space key="all" style={{ padding: "8px" }}>
                    <Checkbox
                      checked={
                        social.split(",").length === socialOptions.length - 1
                      }
                      onChange={() => toggleSocialOption("all")}
                    >
                      All
                    </Checkbox>
                  </Space>
                  {socialOptions
                    .filter((option) => option.value !== "all")
                    .map((option) => (
                      <Space key={option.value} style={{ padding: "8px" }}>
                        <Checkbox
                          checked={social.split(",").includes(option.value)}
                          onChange={() => toggleSocialOption(option.value)}
                        >
                          {option.label}
                        </Checkbox>
                      </Space>
                    ))}
                </div>
              )}
            />
          </ConfigProvider>
        </div>
        {selectedFields.length > 0 && (
          <div className="flex flex-row gap-2 my-4">
            <GospelButton
              className="text-white h-[40px] w-[120px] rounded-md text-sm font-medium transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple "
              label="Run Report"
              onClick={() => {}}
            />
            <GospelButton
              onClick={() => {}}
              label={"Preview Result Count"}
              className="bg-white h-[40px] w-[180px]  rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
            />
          </div>
        )}
      </div>

      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: 22,
              titleColor: "#353b3d",
              fontFamily: "Montserrat, sans-serif",
            },
          },
        }}
      >
        <Modal
          title=""
          open={reportModal}
          footer={null}
          closeIcon={
            <Cancel
              htmlColor="#000"
              fontSize="medium"
              onClick={() => {
                setReportModal(false);
                setCriteria("");
              }}
            />
          }
          className="text-center"
        >
          <div className="flex flex-col items-center my-8">
            <p className=" text-gospel-gray-300 text-base">Select Criteria:</p>
            <GospelSelectBox
              options={criteriaOptions}
              value={criteria}
              onChange={handleAddCriteria}
              containerClass="relative w-full"
              className="w-full h-10 my-4"
              placeholder="Select"
            />
            <GospelButton
              onClick={() => {
                setReportModal(false);
                setCriteria("");
              }}
              label={"Cancel"}
              className="bg-white w-24 py-2 mt-2  rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
            />
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  );
};

export default CreateReport;
