export interface Country {
  code: string;
  id: number;
  name: string;
  orderIndex: number;
}

export interface Language {
  code: string;
  name: string;
}

export interface Filters {
  data: string;
  timeframe: string;
  categories: string;
  country: string;
  language: string;
  shorts: string;
  madeforkids: string;
}

export interface Option {
  value: string;
  label: string;
}

export const dataOptions = [
  { value: "views", label: "Views" },
  { value: "viewschange", label: "Views Change" },
  { value: "subs", label: "Subscribers" },
  { value: "subschange", label: "Subscribers Change" },
  { value: "vpv30", label: "VPV30" },
  { value: "epv30", label: "EPV30" },
  { value: "cpv30", label: "CPV30" },
  { value: "vpv90", label: "VPV90" },
  { value: "epv90", label: "EPV90" },
  { value: "cpv90", label: "CPV90" },
  { value: "shvpv90", label: "Shorts VPV90" },
  { value: "lgvpv90", label: "Longs VPV90" },
];

export const timeFrameOptions = [
  { value: "alltime", label: "All-Time" },
  { value: "last24hours", label: "Last 24 Hours" },
  { value: "last7days", label: "Last 7 Days" },
  { value: "last30days", label: "Last 30 Days" },
];

export const timeChangeFrameOptions = [
  { value: "last7days", label: "Last 7 Days" },
  { value: "last30days", label: "Last 30 Days" },
];

export const categoryOptions = [
  { value: "all", label: "All" },
  { value: "2", label: "Autos & Vehicles" },
  { value: "23", label: "Comedy" },
  { value: "27", label: "Education" },
  { value: "24", label: "Entertainment" },
  { value: "1", label: "Film & Animation" },
  { value: "20", label: "Gaming" },
  { value: "26", label: "Howto & Style" },
  { value: "10", label: "Music" },
  { value: "25", label: "News & Politics" },
  { value: "29", label: "Nonprofits & Activism" },
  { value: "22", label: "People & Blogs" },
  { value: "15", label: "Pets & Animals" },
  { value: "28", label: "Science & Technology" },
  { value: "17", label: "Sports" },
  { value: "19", label: "Travel & Events" },
];

export const shortOptions = [
  { value: "2", label: "Include" },
  { value: "0", label: "Exclude" },
  { value: "1", label: "Only" },
];

export interface LatestVideo {
  thumbnailURL: string;
  title: string;
  uploadDate: string;
  uploadTimestamp: string;
  videoId: string;
  viewCount: number;
}

export type ChannelInfo = {
  aggregateViews: string;
  allTimeSubs: string;
  allTimeViews: string;
  categoryName: string;
  change: string;
  channelId: string;
  channelImage: string;
  country: string;
  gospelPageId: number;
  language: string;
  latestVideo: LatestVideo;
  rank: number;
  slug: string;
  thirtyDaySubs: string;
  thirtyDayViews: string;
  title: string;
  uploads: number;
  views: string;
  subs: string;
  subschange: string;
  vpv30: string;
  epv30: string;
  cpv30: string;
  vpv90: string;
  epv90: string;
  cpv90: string;
  shvpv90: string;
  lgvpv90: string;
}

export interface FilterItem {
  id: number;
  deleted: boolean;
  hidden: boolean;
  gospelUserId: number;
  name: string;
  requestURI: string;
}
