import React, { useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import { get, post } from "../../utility/api";
import GospelSelectBox from "../../common/GospelSelect/GospelSelect";
import GospelButton from "../../common/GospelButton/GospelButton";
import { useParams } from "react-router-dom";
import {
  BrandVideoInfo,
  ChannelData,
  PageInfo,
  TrendingVideoInfo,
  TrendingVideoKey,
} from "../../types/channel";
import {
  ArrowDropDown,
  ArrowDropUp,
  Cancel,
  HomeSharp,
  InfoOutlined,
  PlayArrow,
  RemoveRedEyeSharp,
} from "@mui/icons-material";
import moment from "moment";
import {
  Avatar,
  Collapse,
  ConfigProvider,
  DatePicker,
  Modal,
  Tooltip,
} from "antd";
import { Tabs } from "antd";
import dayjs, { Dayjs } from "dayjs";
import Highcharts, { SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import { CircularProgress } from "@mui/material";
import {
  Platform,
  determineLabelRotation,
  getDynamicTickInterval,
  getPlatform,
  platformIcons,
  quickDateRanges,
} from "../../common/utils";
import {
  channelChartTabs,
  generateCommentsData,
  generateSubscriberData,
  generateUploadsData,
  generateViewData,
  demographicData,
} from "./ChannelUtils";
import ReactPlayer from "react-player";
import { formatNumberWithCommas } from "../../utility/common";
import GospelInput from "../../common/GospelInput/GospelInput";
import { WatchlistItem } from "../../types/watchlist";

HighchartsMore(Highcharts);

const { RangePicker } = DatePicker;

const TrendingVideoTable = ({
  data,
  showTrendingVideos,
}: {
  data: TrendingVideoInfo[];
  showTrendingVideos: boolean;
}) => {
  const [isYoutubeOpen, setIsYoutubeOpen] = useState<boolean>(false);

  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);

  const openModal = (videoId: string) => {
    setSelectedVideoId(videoId);
    setIsYoutubeOpen(true);
  };

  const closeModal = () => {
    setIsYoutubeOpen(false);
    setSelectedVideoId(null);
  };

  return (
    <table className="divide-y divide-gray-200 w-full mx-8">
      <thead>
        <tr className="text-center text-base text-gospel-gray-300">
          <th className="px-4 py-2 w-2/4"></th>
          <th className="px-4 py-2 font-medium w-1/5">Views</th>
          <th className="px-4 py-2 font-medium w-1/5">Comments</th>
          <th className="px-4 py-2 font-medium w-1/5">Likes</th>
        </tr>
      </thead>
      <tbody>
        {data
          .slice(0, showTrendingVideos ? data.length : 5)
          .map((item, index) => (
            <tr
              key={item.videoId}
              className={`text-center text-sm text-gray-800 ${
                index % 2 === 0 ? "bg-gospel-white-100" : "bg-transparent"
              }`}
            >
              <td className="px-4 py-2 flex flex-row gap-4 items-center">
                <div className="bg-gray-200 flex items-center justify-center">
                  <img
                    src={item.thumbnailURL}
                    alt="Video Thumbnail"
                    className="max-w-24 h-14 cursor-pointer"
                    onClick={() => openModal(item.videoId)}
                  />
                  <PlayArrow
                    htmlColor="#fff"
                    className="absolute cursor-pointer"
                    onClick={() => openModal(item.videoId)}
                  />
                </div>
                <div className="flex flex-col text-left">
                  <h3 className="font-medium text-gospel-gray-300">
                    {item.title}
                  </h3>
                  <div className="flex flex-row">
                    <p className="text-sm text-gospel-gray-200 ">
                      {item.uploadDate}
                    </p>
                    <p className="mx-1 text-sm text-gospel-gray-200 ">| </p>
                    <p className="text-sm text-gospel-gray-200 ">
                      {moment(item.uploadTimestamp).format("MM/DD/YYYY, HH:mm")}
                    </p>
                  </div>
                </div>
              </td>
              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                {formatNumberWithCommas(item.viewCount)}
              </td>
              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                {formatNumberWithCommas(item.commentCount)}
              </td>
              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                {formatNumberWithCommas(item.likeCount)}
              </td>
            </tr>
          ))}
        {selectedVideoId && (
          <ConfigProvider
            theme={{
              components: {
                Modal: {
                  titleFontSize: 18,
                  headerBg: "transparent",
                  contentBg: "",
                  titleColor: "#353b3d",
                  boxShadow: "none",
                  fontFamily: "Montserrat, sans-serif",
                },
              },
            }}
          >
            <Modal
              title=""
              open={isYoutubeOpen}
              width={"fit-content"}
              height={"fit-content"}
              footer={null}
              closeIcon={
                <Cancel
                  className="absolute bg-gospel-purple text-white rounded-full bottom-0"
                  fontSize="large"
                  onClick={closeModal}
                />
              }
              className="text-center"
            >
              <ReactPlayer
                controls
                width="750px"
                height={"450px"}
                url={`https://youtube.com/watch?v=${selectedVideoId}`}
              />
            </Modal>
          </ConfigProvider>
        )}

        <ConfigProvider
          theme={{
            components: {
              Modal: {
                titleFontSize: 22,
                titleColor: "#353b3d",
                fontFamily: "Montserrat, sans-serif",
              },
            },
          }}
        ></ConfigProvider>
      </tbody>
    </table>
  );
};

const options = quickDateRanges.map((item) => ({
  value: item.value,
  label: item.label,
}));

const chartLineWidth: number = 5;

const chartInterval: string[] = ["daily", "weekly", "monthly", "all-Time"];

function Channel() {
  const { slug } = useParams();
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [isUploadedYoutubeOpen, setIsUploadedYoutubeOpen] =
    useState<boolean>(false);
  const [uploadedVideoId, setUploadedVideoId] = useState<string | null>(null);
  const [channelInfo, setChannelInfo] = useState<ChannelData>();
  const [brandVideoInfo, setBrandVideoInfo] = useState<BrandVideoInfo[]>([]);
  const [selectedTabs, setSelectedTabs] = useState<number[]>([
    channelChartTabs[0].key,
  ]);
  const [selectedInterval, setSelectedInterval] = useState<string>(
    chartInterval[0]
  );
  const [viewData, setViewData] = useState<any>();
  const [subscriberData, setSubscriberData] = useState<any>();
  const [isYoutubeOpen, setIsYoutubeOpen] = useState<boolean>(false);
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);
  const [commentData, setCommentData] = useState<any>();
  const [uploadData, setUploadData] = useState<any>();
  const [showBrandedVideos, setShowBrandedVideos] = useState<boolean>(false);
  const [loadingChart, setLoadingChart] = useState<boolean>(false);
  const [loadChannelData, setLoadChannelData] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().subtract(30, "days").startOf("day"),
    endDate: dayjs().endOf("day"),
  });
  const [trendingVideoInfo, setTrendingVideoInfo] = useState<{
    mostRecent: TrendingVideoInfo[];
    mostViewed: TrendingVideoInfo[];
  }>({ mostRecent: [], mostViewed: [] });
  const [watchlistModalOpen, setWatchlistModalOpen] = useState<boolean>(false);
  const [watchlistListModalOpen, setWatchlistListModalOpen] =
    useState<boolean>(false);
  const [watchList, setWatchList] = useState<WatchlistItem[]>([]);
  const [newWatchlistName, setNewWatchlistName] = useState<string>("");
  const [watchlistNameError, setWatchlistNameError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [addWatchlistloading, setAddWatchlistLoading] =
    useState<boolean>(false);
    const [selectedVideo, setSelectedVideo] = useState([]);
  const [showTrendingVideos, setShowTrendingVideos] = useState(false);
  const [activeTab, setActiveTab] = useState<TrendingVideoKey>("mostViewed");
  const [chartData, setChartData] = useState<Highcharts.Options>({
    chart: {
      type: "line",
      style: {
        fontSize: "16px",
        color: "#666666",
        fontFamily: "Montserrat, sans-serif",
      },
    },
    title: {
      text: undefined,
    },
    legend: {
      enabled: false,
    },

    series: [
      {
        type: "line",
        name: channelChartTabs[0].label,
        // data: [9, 4, 7, 1, 6],
        color: channelChartTabs[0].dataColor,
        lineWidth: chartLineWidth,
      },
    ],
    tooltip: {
      useHTML: true,
      backgroundColor: "transparent", // Remove background color
      borderWidth: 0, // Remove border
      shadow: false, // Remove shadow
    },
    xAxis: {
      type: "datetime",
      crosshair: {
        color: "black",
        width: 4,
      },
      categories: [],
      labels: {
        format: "{value:%b %e}",
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: [
      {
        title: {
          text: "Views",
        },
        min: 0,
      },
    ],
    plotOptions: {
      series: {
        zIndex: 100,
        clip: true,
      },
    },
  });
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const fetchWatchlist = async () => {
    try {
      const result: any = await get("/gospel/watchlist/list");
      if (result) {
        setWatchList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const createNewWatchlist = async () => {
    try {
      setLoading(true);
      if (newWatchlistName.trim() === "") {
        setWatchlistNameError("Please enter a watchlist name.");
        return;
      }
      await get(`/gospel/watchlist/create?title=${newWatchlistName}`);
      fetchWatchlist();
      setNewWatchlistName("");
      setWatchlistNameError("");
      setLoading(false);
    } catch (error) {
      console.error("Error creating new watchlist:", error);
      setLoading(false);
    }
  };

  const addToWatchlist = async (watchlistId: number, channelId: string) => {
    try {
      setAddWatchlistLoading(true);
      const data = new URLSearchParams();
      data.append("list_id", watchlistId.toString());
      data.append("channel_id", channelId);

      const response = await post("/gospel/watchlist/add", data);
      if (response) {
        setAddWatchlistLoading(false);
      }
      setWatchlistModalOpen(false);
      fetchWatchlist();
      fetchPageData();
    } catch (error) {
      console.error("Error adding ̦to watchlist:", error);
      setAddWatchlistLoading(false);
    }
  };

  const closeChartModal = () => {
    setIsUploadedYoutubeOpen(false);
    setUploadedVideoId(null);
  };

  const handleIntervalChange = (interval: string) => {
    setSelectedInterval(interval);
    setSelectedValue("");
    updateDateRange(interval);
  };

  const updateDateRange = (interval: string) => {
    const now = dayjs();
    let startDate;

    switch (interval) {
      case "daily":
        startDate = now.subtract(30, "days");
        break;
      case "weekly":
        startDate = now.subtract(90, "days");
        break;
      case "monthly":
        startDate = now.subtract(365, "days");
        break;
      case "all-Time":
        startDate = now.subtract(1095, "days");
        break;
      default:
        startDate = now.subtract(30, "days");
    }

    setDateRange({ startDate, endDate: now });
  };

  const handleSelectChange = (value: string) => {
    const tempStartDate =
      quickDateRanges.find((e) => e.value === value)?.startDate ?? dayjs();
    const tempEndDate =
      quickDateRanges.find((e) => e.value === value)?.endDate ?? dayjs();
    setSelectedInterval(chartInterval[0]);
    setSelectedValue(value);
    setDateRange((prev) => {
      return {
        ...prev,
        startDate: tempStartDate,
        endDate: tempEndDate,
      };
    });

    getGraphData(
      channelChartTabs
        .filter((e) => selectedTabs.includes(e.key))
        .map((e) => e),
      pageInfo?.socialNetworks[0].identifier ?? "",
      tempStartDate.valueOf(),
      tempEndDate.valueOf(),
      selectedInterval
    );
  };

  const handleRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (!dates || !dates[0] || !dates[1]) {
      console.warn("Invalid dates selected:", dates);
      return;
    }

    let startDate = dayjs(dates[0]).startOf("day");
    let endDate = dayjs(dates[1]).endOf("day");

    // Enforce maximum range of 3 years (1095 days)
    if (endDate.diff(startDate, "days") > 1095) {
      startDate = dayjs().subtract(3, "years").startOf("day");
      endDate = dayjs().endOf("day");
      console.warn("Adjusted Range due to max limit:", { startDate, endDate });
    }

    setSelectedValue(""); // Reset quick range
    setDateRange({ startDate, endDate });

    // Fetch graph data
    getGraphData(
      channelChartTabs
        .filter((e) => selectedTabs.includes(e.key))
        .map((e) => e),
      pageInfo?.socialNetworks[0]?.identifier ?? "",
      startDate.valueOf(),
      endDate.valueOf(),
      selectedInterval
    );
  };

  const openModal = (videoId: string) => {
    setSelectedVideoId(videoId);
    setIsYoutubeOpen(true);
  };

  const fetchPageData = async () => {
    try {
      setLoadChannelData(true);
      setLoadingChart(true);
      const result = await get<PageInfo>(`/gospel/page/info?slug=${slug}`);
      if (result) {
        setPageInfo(result);
        const channelData = await get<ChannelData>(
          `/youtube/channel/overview?channel_id=${result?.socialNetworks[0].identifier}`
        );
        const channelId = result?.socialNetworks[0].identifier;

        if (channelData) {
          setChannelInfo(channelData);
          const viewData = generateViewData(channelData);
          const subscriberData = generateSubscriberData(channelData);
          const commentData = generateCommentsData(channelData);
          const uploadData = generateUploadsData(channelData);
          setViewData(viewData);
          setCommentData(commentData);
          setSubscriberData(subscriberData);
          setUploadData(uploadData);
        }
        const brandedVideoResult: any = await get<BrandVideoInfo>(
          `/youtube/channel/videos/branded?channel_id=${result?.socialNetworks[0].identifier}`
        );
        if (brandedVideoResult) {
          setBrandVideoInfo(brandedVideoResult);
        }
        const [mostRecentVideos, mostViewedVideos] = await Promise.all([
          get<TrendingVideoInfo[]>(
            `/youtube/channel/videos/mostrecent?channel_id=${channelId}`
          ),
          get<TrendingVideoInfo[]>(
            `/youtube/channel/videos/mostviewed?channel_id=${channelId}`
          ),
        ]);

        setTrendingVideoInfo({
          mostRecent: mostRecentVideos || [],
          mostViewed: mostViewedVideos || [],
        });
        getGraphData(
          channelChartTabs
            .filter((e) => selectedTabs.includes(e.key))
            .map((e) => e),
          result?.socialNetworks[0].identifier ?? "",
          dateRange.startDate.valueOf(),
          dateRange.endDate.valueOf(),
          selectedInterval
        );
        setLoadChannelData(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPageData();
    fetchWatchlist();
  }, [slug, selectedInterval, dateRange]);

  const getGraphData = async (
    sTabs: {
      dataGraph: string;
      label: string;
      dataColor: string;
      keyName: string;
    }[],
    channel_id: string,
    startDate: number,
    endDate: number,
    interval: string
  ) => {
    let series: Highcharts.SeriesOptionsType[] = [];
    let xAxisCategories: string[] = [];
    let graphData: any;

    try {
      await Promise.all(
        sTabs.map(async (tab, i) => {
          try {
            graphData = await get<BrandVideoInfo>(
              `/youtube/stats/channel/${sTabs[i].dataGraph}?channel_id=${channel_id}&start=${startDate}&end=${endDate}&interval=${interval}`
            );

            if (graphData) {
              graphData = graphData.reverse();
              if (sTabs[i].dataGraph === "uploads") {
                series.push({
                  type: "bubble",
                  name: sTabs[i].label,
                  data: graphData.map((e: any) => ({
                    x: dayjs(e.date).valueOf(), // X-coordinate (date)
                    y: -9999, // A fixed Y value well outside the visible range
                    uploadCount: e.uploadCount, // Custom property for upload count
                  })),

                  marker: {
                    fillColor: sTabs[i].dataColor, // Color for the bubble
                    lineWidth: 0, // No border for bubbles
                    fillOpacity: 1,
                    radius: 10, // Keep radius the same for consistent bubble size
                    symbol: "circle", // Ensure the bubble is fully round
                  },

                  dataLabels: {
                    enabled: true,
                    format: "{point.uploadCount}", // Display the upload count in the bubble
                    align: "center",
                    verticalAlign: "middle",
                    style: {
                      color: "white", // Text color inside the bubble
                      textOutline: "none", // No text outline
                      fontSize: "12px", // Text size
                    },
                    inside: true, // Ensure the text stays inside the bubble
                    overflow: "allow",
                    crop: false,
                    zIndex: 6,
                  },
                  yAxis: i,
                  lineWidth: 0,
                  zIndex: 6, // Ensure the bubbles render above axis and grid lines
                  clip: false, // Prevent bubbles from being clipped by chart boundaries
                  maxSize: "14%", // Ensure proportional bubble sizing
                  minSize: "7%", // Ensure proportional bubble sizing
                });
              } else {
                xAxisCategories = graphData.map((e: any) =>
                  dayjs(e.date).valueOf()
                );
                const seriesData = graphData.map((e: any) => ({
                  x: dayjs(e.date).valueOf(),
                  y: e[sTabs[i].keyName],
                  color:
                    e[sTabs[i].keyName] < 0
                      ? "rgb(189, 57, 73)"
                      : sTabs[i].dataColor,
                }));

                series.push({
                  type: "line",
                  name: sTabs[i].label,
                  yAxis: sTabs[i].dataGraph === "uploads" ? 0 : i,
                  data: seriesData,
                  color: sTabs[i].dataColor,
                  lineWidth: 4,
                  marker: {
                    fillColor: sTabs[i].dataColor, // Color for the bubble
                    lineWidth: 0, // No border for bubbles
                    fillOpacity: 1,
                    radius: 4, // Keep radius the same for consistent bubble size
                    symbol: "circle", // Ensure the bubble is fully round
                  },
                  zones: [
                    {
                      value: 0,
                      color: "rgb(189, 57, 73)",
                    },
                    {
                      color: sTabs[i].dataColor,
                    },
                  ],
                } as Highcharts.SeriesLineOptions);
              }
            }
          } catch (error) {
            console.error(
              `Error fetching data for ${sTabs[i].dataGraph}:`,
              error
            );
          }
        })
      );
      const dayDifference: number = moment(endDate).diff(
        moment(startDate),
        "days"
      );
      const labelRotation = determineLabelRotation(
        dayDifference,
        selectedInterval
      );
      let dateFormat: any;
      if (interval === "daily" && selectedValue === "last12months") {
        dateFormat = "{value:%b %Y}"; // Example: Nov 2023
      } else if (interval === "daily" || interval === "weekly") {
        dateFormat = "{value:%b %e}"; // Example: Nov 4
      } else if (interval === "all-Time" || interval === "monthly") {
        dateFormat = "{value:%b %Y}"; // Example: Nov 2023
      } else if (dayDifference >= 365) {
        dateFormat = "{value:%b %Y}"; // Default to day/month
      } else {
        dateFormat = "{value:%b %e}"; // Default to day/month
      }
      let tickInterval: number | undefined;
      if (
        selectedValue === "last90days" ||
        selectedValue === "last6months" ||
        selectedValue === "last12months"
      ) {
        tickInterval = getDynamicTickInterval(
          dateRange.startDate.valueOf(),
          dateRange.endDate.valueOf(),
          selectedValue
        );
      } else {
        tickInterval = getDynamicTickInterval(
          dateRange.startDate.valueOf(),
          dateRange.endDate.valueOf()
        );
      }
      // Update state only if series and xAxisCategories have been updated
      if (series.length > 0 && xAxisCategories.length > 0) {
        setChartData((prev: any) => ({
          ...prev,

          series: series,
          tooltip: {
            useHTML: true,
            backgroundColor: "transparent", // Remove background color
            borderWidth: 0, // Remove border
            shadow: false, // Remove shadow
            formatter: function () {
              const x: number = this.point.x as number;
              let date: string;
              let startOfWeek: string;
              let endOfWeek: string;

              // Now 'date' explicitly typed as 'string'
              if (interval === "monthly") {
                date = moment.utc(x).format("MMM YYYY");
              } else if (interval === "all-Time") {
                date = moment.utc(x).format("DD MMM YYYY");
              } else if (interval === "weekly") {
                const pointDate = moment.utc(x);
                const startOfWeek = pointDate.format("MMM DD"); // Start date is the point date
                const endOfWeek = pointDate
                  .clone()
                  .add(6, "days")
                  .format("MMM DD"); // Add 6 days to get the end date

                date = `${startOfWeek} - ${endOfWeek}`;
              } else {
                date = moment.utc(x).format("MMM DD");
              }

              // Construct tooltip content for all tabs (series)
              let tooltipContent = sTabs
                .map((item, index) => {
                  if (item.dataGraph !== "uploads") {
                    // Get the corresponding y value for the current x value from each series
                    const yValue = this.series.chart.series[index].data.find(
                      (point) => point.x === x
                    )?.y;

                    const formattedValue = Highcharts.numberFormat(
                      yValue as number,
                      0,
                      ".",
                      ","
                    );

                    return `
                  <span class="tooltipNumber">
                    <span class="tooltipColor" style="background:${
                      item.dataColor
                    }"></span>
                    <span class="tooltipKey" style="color:${item.dataColor}"> ${
                      item.label
                    }: </span>
                    ${formattedValue || "N/A"}
                  </span>
                `;
                  }
                })
                .join("");

              return `
                <div class="tooltip">
                  <span class="tooltipDate">${date}</span>
                  ${tooltipContent}
                </div>
              `;
            },
          },
          xAxis: {
            ...prev.xAxis,
            categories: xAxisCategories,
            tickInterval: tickInterval,
            type: "datetime",
            minPadding: 0.05,
            maxPadding: 0.05,
            labels: {
              format: dateFormat,
              rotation: labelRotation,
              style: {
                fontSize: "12px",
                color: "#666666",
                fontFamily: "Montserrat, sans-serif",
                textOverflow: "none",
                whiteSpace: "nowrap",
              },
              y: 35,
            },
            tickWidth: 0.5,
            startOnTick: true,
            endOnTick: true,
            showFirstLabel: true,
            showLastLabel: true,
            tickmarkPlacement: "on", // Ensure ticks are aligned with labels
          },

          yAxis: series.map((e: Highcharts.SeriesOptionsType, i: number) => {
            if (sTabs[i].dataGraph === "uploads") {
              return {
                visible: false, // Hide Y-axis for bubbles
                title: {
                  text: null,
                },
                min: -9999, // Ensure bubbles stay at the bottom
                max: 0, // Limit the range
                labels: {
                  enabled: false,
                },
              };
            }
            const seriesData = (e as Highcharts.SeriesLineOptions).data || [];

            const yMin =
              seriesData.length > 0
                ? Math.min(...seriesData.map((d: any) => d.y))
                : 0;
            const yMax =
              seriesData.length > 0
                ? Math.max(...seriesData.map((d: any) => d.y))
                : 0;

            return {
              title: {
                text: e.name,
              },
              min: yMin < 0 ? yMin : 0,
              max: yMax,
              visible: true,
              labels: {
                enabled: true,
                formatter: function () {
                  const value = this.value as number;

                  if (Math.abs(value) >= 1000000000) {
                    return value / 1000000000 + "B"; // Include sign for billions
                  } else if (Math.abs(value) >= 1000000) {
                    return value / 1000000 + "M"; // Include sign for millions
                  } else if (Math.abs(value) >= 1000) {
                    return value / 1000 + "K"; // Include sign for thousands
                  } else {
                    return value.toString(); // Show integer values for less than 1000
                  }
                },
                style: {
                  fontSize: "12px",
                  color: "#666666",
                  fontFamily: "Montserrat, sans-serif",
                  textOverflow: "none",
                  whiteSpace: "nowrap",
                },
              },

              opposite: i % 2 === 0 ? false : true,
            };
          }),
          exporting: {
            width: 2000,
            sourceHeight: 300,
            buttons: {
              contextButton: {
                menuItems: ["downloadJPEG", "downloadCSV"],
              },
            },
          },
          plotOptions: {
            series: {
              zIndex: 100,
            },

            bubble: {
              zIndex: 5,
              sizeBy: "width", // Scale bubbles based on chart width
              minSize: 10, // Minimum bubble size
              maxSize: 20, // Maximum bubble size
              point: {
                events: {
                  click: function () {
                    const { x, y, z } = this.options; // Access the x-coordinate (date)
                    // Convert the x value (timestamp) to the date format used in your graphData
                    const clickedBubble = dayjs(x).format("YYYY-MM-DD");

                    // Find the matching entry in the graphData based on the date
                    const bubbleData = graphData.find(
                      (data: any) =>
                        dayjs(data.date).format("YYYY-MM-DD") === clickedBubble
                    );

                    // Get the first video's videoId (assuming only one video per date)
                    const videoId = bubbleData.videos[0]?.videoId;

                    setIsUploadedYoutubeOpen(true);
                    setUploadedVideoId(videoId);
                  },
                },
              },
            },
          },
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingChart(false);
    }
  };

  const toggleShowBrandedVideos = () => {
    setShowBrandedVideos(!showBrandedVideos);
  };

  const formatNumberWithCommas = (number: number | string) => {
    if (typeof number === "string") {
      number = parseFloat(number.replace(/,/g, "")); // Convert string to a floating-point number
    }

    const [integerPart, decimalPart] = number.toString().split(".");

    // Format the integer part with commas
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    // Combine the formatted integer part with the decimal part if it exists
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  };

  const handleTabClick = (key: number) => {
    setSelectedTabs((prevSelectedTabs) => {
      const sTabs = prevSelectedTabs.includes(key)
        ? prevSelectedTabs.filter((tabKey) => tabKey !== key)
        : [...prevSelectedTabs, key];

      getGraphData(
        channelChartTabs.filter((e) => sTabs.includes(e.key)).map((e) => e),
        pageInfo?.socialNetworks[0].identifier ?? "",
        dateRange.startDate.valueOf(),
        dateRange.endDate.valueOf(),
        selectedInterval
      );

      return sTabs;
    });
  };

  const platforms: Platform[] = (pageInfo?.links ?? [])
    .map((url: string) => getPlatform(url))
    .filter((value, index, self) => self.indexOf(value) === index);

  const handleToggle = (key: string) => {
    setActiveKeys((prevKeys) => {
      const currentSet = new Set(prevKeys);
      if (currentSet.has(key)) {
        currentSet.delete(key);
      } else {
        currentSet.add(key);
      }
      return Array.from(currentSet);
    });
  };
  const collapseViewItems = (viewData || []).map(
    (data: any, index: number) => ({
      key: index.toString(),
      label: (
        <div
          className="w-full py-6 border-b-2 border-gray-300 relative  flex items-center justify-between cursor-pointer"
          onClick={() => handleToggle(index.toString())}
          key={index.toString()}
        >
          <div>
            <span className="text-lg font-semibold inline-block relative">
              {data.title}
              <Tooltip placement="top" title={data.info}>
                {data.key !== "last24Hours" && (
                  <InfoOutlined htmlColor="#e2e3e3" className="mx-1" />
                )}
              </Tooltip>
            </span>
            <span className="text-xs opacity-30 relative">
              {data.description}
            </span>
          </div>
          {data.children && data.children.length > 0 && (
            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-gray-300 text-white px-3 text-xs rounded-t-lg cursor-pointer hover:bg-gray-800">
              {activeKeys.includes(index.toString()) ? (
                <ArrowDropUp style={{ fontSize: 20 }} />
              ) : (
                <ArrowDropDown style={{ fontSize: 20 }} />
              )}
            </span>
          )}
          <div className="flex items-center gap-2">
            <span className="float-right text-lg font-semibold text-gray-800">
              {formatNumberWithCommas(data.value)}
            </span>
            {data.percentChange ? (
              <span
                className={`w-[66px] float-right text-base ${
                  data.percentChange?.startsWith("-")
                    ? "text-gospel-error-red"
                    : "text-green-500"
                }`}
              >
                {data.percentChange?.startsWith("-")
                  ? `${data.percentChange}`
                  : `+${data.percentChange}`}
              </span>
            ) : (
              <span className="w-[66px]"></span>
            )}
          </div>
        </div>
      ),
      children:
        data.children &&
        data.children.length > 0 &&
        data.children.map((child: any, childIndex: number) => (
          <div
            key={childIndex}
            className="w-full py-6 border-b-2 border-gray-300 relative flex items-center justify-between cursor-pointer"
          >
            <div>
              <span className="text-lg font-semibold inline-block relative text-gospel-gray-400">
                {child.title}
                <Tooltip placement="top" title={child.info}>
                  <InfoOutlined htmlColor="#e2e3e3" className="mx-1" />
                </Tooltip>
              </span>
              <span className="text-xs opacity-30 relative text-gospel-gray-400">
                {child.description}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="float-right text-lg font-semibold text-gospel-gray-400">
                {formatNumberWithCommas(child.value)}
              </span>
              {child.percentChange ? (
                <span
                  className={`w-[66px] float-right text-base ${
                    child.percentChange?.startsWith("-")
                      ? "text-gospel-error-red"
                      : "text-green-500"
                  }`}
                >
                  {child.percentChange?.startsWith("-")
                    ? `${child.percentChange}`
                    : `+${child.percentChange}`}
                </span>
              ) : (
                <span className="w-[66px]"></span>
              )}
            </div>
          </div>
        )),
    })
  );

  const collapseCommentsItems = (commentData || []).map(
    (data: any, index: number) => ({
      key: index.toString(),
      label: (
        <div
          className="w-full py-6 border-b-2 border-gray-300 relative  flex items-center justify-between cursor-pointer"
          onClick={() => handleToggle(index.toString())}
          key={index.toString()}
        >
          <div>
            <span className="text-lg font-semibold inline-block relative">
              {data.title}
              <Tooltip placement="top" title={data.info}>
                {data.key !== "last24Hours" && (
                  <InfoOutlined htmlColor="#e2e3e3" className="mx-1" />
                )}
              </Tooltip>
            </span>
            <span className="text-xs opacity-30 relative">
              {data.description}
            </span>
          </div>
          {data.children && data.children.length > 0 && (
            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-gray-300 text-white px-3 text-xs rounded-t-lg cursor-pointer hover:bg-gray-800">
              {activeKeys.includes(index.toString()) ? (
                <ArrowDropUp style={{ fontSize: 20 }} />
              ) : (
                <ArrowDropDown style={{ fontSize: 20 }} />
              )}
            </span>
          )}
          <div className="flex items-center gap-2">
            <span className="float-right text-lg font-semibold text-gray-800">
              {formatNumberWithCommas(data.value)}
            </span>
            {data.percentChange ? (
              <span
                className={`w-[66px] float-right text-base ${
                  data.percentChange?.startsWith("-")
                    ? "text-gospel-error-red"
                    : "text-green-500"
                }`}
              >
                {data.percentChange?.startsWith("-")
                  ? `${data.percentChange}`
                  : `+${data.percentChange}`}
              </span>
            ) : (
              <span className="w-[66px]"></span>
            )}
          </div>
        </div>
      ),
      children:
        data.children &&
        data.children.length > 0 &&
        data.children.map((child: any, childIndex: number) => (
          <div
            key={childIndex}
            className="w-full py-6 border-b-2 border-gray-300 relative flex items-center justify-between cursor-pointer"
          >
            <div>
              <span className="text-lg font-semibold inline-block relative text-gospel-gray-400">
                {child.title}
                <Tooltip placement="top" title={child.info}>
                  <InfoOutlined htmlColor="#e2e3e3" className="mx-1" />
                </Tooltip>
              </span>
              <span className="text-xs opacity-30 relative text-gospel-gray-400">
                {child.description}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="float-right text-lg font-semibold text-gospel-gray-400">
                {formatNumberWithCommas(child.value)}
              </span>
              {child.percentChange ? (
                <span
                  className={`w-[66px] float-right text-base ${
                    child.percentChange?.startsWith("-")
                      ? "text-gospel-error-red"
                      : "text-green-500"
                  }`}
                >
                  {child.percentChange?.startsWith("-")
                    ? `${child.percentChange}`
                    : `+${child.percentChange}`}
                </span>
              ) : (
                <span className="w-[66px]"></span>
              )}
            </div>
          </div>
        )),
    })
  );

  return (
    <div className="overflow-hidden flex flex-col min-h-screen">
      <Header />
      <div className="w-full px-8 overflow-hidden block bg-black relative mt-[70px]">
        <div className="relative mt-8 flex items-center z-1 z-10">
          <div className="absolute top-[30px] right-4 h-[40px] flex flex-row gap-2 items-center">
            {pageInfo?.watchlists && pageInfo.watchlists.length > 0 && (
              <div className="flex flex-col">
                <span className="text-white text-sm">{`Added to`}</span>
                <span
                  className="text-gospel-purple text-sm cursor-pointer"
                  onClick={() => setWatchlistListModalOpen(true)}
                >
                  {` ${pageInfo.watchlists.length} ${pageInfo.watchlists.length === 1 ? "Watchlist" : "Watchlists"}`}
                </span>
              </div>
            )}
            <GospelButton
              className="text-white rounded-md text-sm font-medium transition duration-500 cursor-pointer bg-white py-2"
              label={
                <span className="flex flex-row px-4 items-center gap-2">
                  <RemoveRedEyeSharp className="text-gospel-gray-300" />{" "}
                  <h4 className="text-gospel-purple">Add to Watchlist</h4>
                </span>
              }
              onClick={() => setWatchlistModalOpen(true)}
            />
          </div>

          <img
            src={pageInfo?.channelImage}
            className="w-52 h-52 rounded-full overflow-hidden mr-5 md:w-100 md:h-auto"
            alt="title"
          />
          <div className="flex flex-col items-start justify-start gap-3">
            <h1 className="text-2xl leading-tight font-semibold text-white">
              {pageInfo?.title}
            </h1>

            <div className="flex flex-row items-center">
              <Avatar
                src={
                  <img
                    alt={pageInfo?.countryName}
                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                      pageInfo?.countryCode.toUpperCase() || ""
                    }.svg`}
                    className="rounded-full"
                  />
                }
              />

              <span className="text-15 text-white font-medium leading-none ml-2">
                {pageInfo?.countryName}
              </span>
            </div>

            <div className="gap-3 flex justify-center items-center">
              {platforms.map((platform) => (
                <a
                  key={platform}
                  href={
                    pageInfo?.links?.find(
                      (url) => getPlatform(url) === platform
                    ) || "#"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gospel-gray-400 opacity-50 hover:opacity-100"
                >
                  {platformIcons[platform]}
                </a>
              ))}
              <a
                href={`https://www.youtube.com/channel/${pageInfo?.socialNetworks[0]?.identifier}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gospel-gray-400 opacity-50 hover:opacity-100"
              >
                {platformIcons["youtube"]}
              </a>
            </div>
          </div>
        </div>
        <div className="text-center pb-2">
          <span
            className="text-white pointer-events-none cursor-default border-b-gospel-purple pt-6 pb-1.5 mb-4 px-[20px]"
            style={{ borderBottom: "5px solid" }}
          >
            Youtube
          </span>
        </div>
      </div>
      <div className="py-10 px-4">
        <div className="flex flex-row justify-between">
          <div className="w-full sm:w-1/2 md:w-1/4 text-center mb-4">
            <span className="text-3xl font-bold block mb-1">
              {formatNumberWithCommas(channelInfo?.views ?? 0)}
            </span>
            <span className="text-xs md:text-sm font-semibold text-gospel-gray-400">
              Views
            </span>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/4 text-center mb-4">
            <span className="text-3xl  font-bold block mb-1">
              {channelInfo?.subs ?? 0}
            </span>
            <span className="text-xs md:text-sm font-semibold text-gospel-gray-400">
              Subscribers
            </span>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/4 text-center mb-4">
            <span className="text-3xl font-bold block mb-1">
              {channelInfo?.videos}
            </span>
            <span className="text-xs md:text-sm font-semibold text-gospel-gray-400">
              Uploads
            </span>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/4 text-center mb-4">
            <span className="text-3xl font-bold block mb-1">
              {moment(channelInfo?.createdDate).format("MM/DD/YYYY")}
            </span>
            <span className="text-xs md:text-sm font-semibold text-gospel-gray-400">
              Created
            </span>
          </div>
        </div>

        <div className="bg-white z-50 min-h-400px py-10 px-3 shadow-md my-4">
          <div className="flex flex-wrap justify-center items-center gap-3">
            {channelChartTabs.map((tab) => (
              <div
                className="w-full sm:w-auto md:w-auto text-center mb-4"
                key={tab.key}
              >
                <GospelButton
                  className={`h-[40px] rounded-md text-sm font-medium transition duration-500 cursor-pointer px-6 ${
                    selectedTabs.includes(tab.key)
                      ? "text-white"
                      : "text-gospel-gray-400 bg-gospel-white-100 hover:text-gospel-gray-300"
                  }`}
                  style={{
                    backgroundColor: selectedTabs.includes(tab.key)
                      ? tab.dataColor
                      : "",
                  }}
                  label={tab.label}
                  onClick={() => handleTabClick(tab.key)}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-wrap md:flex-nowrap">
              {chartInterval.map((interval) => (
                <button
                  key={interval}
                  className={`bg-white flex items-center cursor-pointer text-sm px-4 py-3 transition-colors duration-500 ease-in-out hover:text-gospel-gray-300  font-bold${
                    selectedInterval === interval
                      ? "bg-gospel-gray-300  text-gospel-gray-300"
                      : "bg-white text-gospel-gray-400"
                  }`}
                  onClick={() => handleIntervalChange(interval)}
                  onKeyUp={() => {}}
                >
                  <span
                    className={`${
                      selectedInterval === interval
                        ? "bg-gospel-purple border-gospel-purple"
                        : "bg-white border-gospel-gray-500"
                    } rounded-full inline-block w-4 h-4 mr-1 border-2  hover:text-gray-500 transition-border duration-500 ease-in-out relative`}
                  ></span>
                  {interval.charAt(0).toUpperCase() + interval.slice(1)}
                </button>
              ))}
            </div>

            <div className="mt-4 md:mt-0 flex flex-col md:flex-row items-center gap-4">
              <RangePicker
                className="appearance-none w-64 h-10 px-3 text-sm text-gospel-purple font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                defaultValue={[dateRange.startDate, dateRange.endDate]}
                value={[dateRange.startDate, dateRange.endDate]}
                disabledDate={(current) => {
                  // Disable dates after today
                  return current && current > dayjs().endOf("day");
                }}
                onChange={handleRangeChange}
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") {
                //     handleRangeChange([dateRange.startDate, dateRange.endDate]);
                //   }
                // }}
                format="MM/DD/YYYY"
              />

              <div className="relative inline-block text-left">
                <GospelSelectBox
                  options={options}
                  value={selectedValue}
                  onChange={handleSelectChange}
                  containerClass="relative max-w-[450px]"
                  className="w-60 h-10"
                  placeholder="Quick Date Ranges"
                />
              </div>
            </div>
          </div>
          <div
            className={`bg-white z-50 min-h-400 pt-6 pb-10 px-3 shadow-md relative  ${
              loadingChart ? "opacity-20" : ""
            }`}
          >
            {loadingChart && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                <CircularProgress
                  className="text-gospel-gray-300 font-bold z-10"
                  size={30}
                />
              </div>
            )}
            <HighchartsReact highcharts={Highcharts} options={chartData} />
          </div>
        </div>
        <div>
          {loadChannelData ? (
            <div className="p-8 flex items-center justify-center bg-white">
              <CircularProgress
                className="text-gospel-gray-300 font-bold z-10"
                size={30}
              />
            </div>
          ) : (
            <>
              <div className="flex bg-white shadow-md p-8 justify-between flex-row gap-8">
                {/* {Views} */}
                <div className="w-full">
                  <div className="py-5 border-b-2 border-gray-300 w-full text-center">
                    <span className="text-xl font-semibold text-center">
                      Views
                    </span>
                  </div>

                  <ConfigProvider
                    theme={{
                      components: {
                        Collapse: {
                          headerPadding: 0,
                          fontFamily: "Montserrat, sans-serif",
                        },
                      },
                    }}
                  >
                    <Collapse
                      accordion={false}
                      activeKey={activeKeys}
                      ghost={true}
                      onChange={(keys: string | string[]) => {
                        // Ensure we're dealing with an array
                        const newKeys = Array.isArray(keys) ? keys : [keys];
                        setActiveKeys(newKeys);
                      }}
                    >
                      {collapseViewItems.map((item: any) => (
                        <Collapse.Panel
                          key={item.key}
                          header={item.label}
                          showArrow={false}
                        >
                          {item.children}
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                  </ConfigProvider>
                </div>
                {/* {Subscribers} */}
                <div className="w-full">
                  <div className="py-5 border-b-2 border-gray-300 w-full text-center">
                    <span className="text-xl font-semibold text-center">
                      Subscribers
                    </span>
                  </div>

                  {subscriberData &&
                    subscriberData.map((data: any) => (
                      <div
                        className="w-full pt-8 border-b-2 border-gray-300 relative pb-7 flex items-center justify-between"
                        key={data.key}
                      >
                        <div>
                          <span className="text-lg font-semibold inline-block relative">
                            {data.title}
                            <Tooltip placement="top" title={data.info}>
                              {data.key !== "last24Hours" && (
                                <InfoOutlined
                                  htmlColor="#e2e3e3"
                                  className="mx-1"
                                />
                              )}
                            </Tooltip>
                          </span>
                          <span className="text-sm opacity-30 relative ">
                            {data.description}
                          </span>
                        </div>

                        <div className="flex items-center gap-2">
                          <span className="float-right text-lg font-semibold text-gray-800">
                            {formatNumberWithCommas(data.value)}
                          </span>
                          {data.percentChange ? (
                            <span
                              className={`w-[66px] float-right text-base ${
                                data.percentChange?.startsWith("-")
                                  ? "text-gospel-error-red"
                                  : "text-green-500"
                              }`}
                            >
                              {data.percentChange?.startsWith("-")
                                ? `${data.percentChange}`
                                : `+${data.percentChange}`}
                            </span>
                          ) : (
                            <span className="w-[66px]"></span>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="flex bg-white shadow-md p-8 justify-between flex-row gap-8">
                {/* {Comments and Likes} */}
                <div className="w-full">
                  <div className="py-5 border-b-2 border-gray-300 w-full text-center">
                    <span className="text-xl font-semibold text-center">
                      Comments and Likes
                    </span>
                  </div>

                  <ConfigProvider
                    theme={{
                      components: {
                        Collapse: {
                          headerPadding: 0,
                          fontFamily: "Montserrat, sans-serif",
                        },
                      },
                    }}
                  >
                    <Collapse
                      accordion={false}
                      activeKey={activeKeys}
                      ghost={true}
                      onChange={(keys: string | string[]) => {
                        // Ensure we're dealing with an array
                        const newKeys = Array.isArray(keys) ? keys : [keys];
                        setActiveKeys(newKeys);
                      }}
                    >
                      {collapseCommentsItems.map((item: any) => (
                        <Collapse.Panel
                          key={item.key}
                          header={item.label}
                          showArrow={false}
                        >
                          {item.children}
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                  </ConfigProvider>
                </div>

                {/* {Uploads} */}
                <div className="w-full">
                  <div className="py-5 border-b-2 border-gray-300 w-full text-center">
                    <span className="text-xl font-semibold text-center">
                      Uploads
                    </span>
                  </div>

                  {uploadData &&
                    uploadData.map((data: any) => (
                      <div
                        className="w-full pt-8 border-b-2 border-gray-300 relative pb-7 flex items-center justify-between"
                        key={data.key}
                      >
                        <div>
                          <span className="text-lg font-semibold inline-block relative">
                            {data.title}
                          </span>
                          <span className="text-sm opacity-30 relative ">
                            {data.description}
                          </span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="float-right text-lg font-semibold text-gray-800">
                            {formatNumberWithCommas(data.value)}
                          </span>
                          {data.percentChange ? (
                            <span
                              className={`w-[66px] float-right text-base ${
                                data.percentChange?.startsWith("-")
                                  ? "text-gospel-error-red"
                                  : "text-green-500"
                              }`}
                            >
                              {data.percentChange?.startsWith("-")
                                ? `${data.percentChange}`
                                : `+${data.percentChange}`}
                            </span>
                          ) : (
                            <span className="w-[66px]"></span>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* {Branded Videos} */}
      {brandVideoInfo && brandVideoInfo.length > 0 && (
        <div className="px-4 mb-12">
          <div className="flex flex-col bg-white shadow-md py-8">
            {loadChannelData ? (
              <div className="p-8 flex items-center justify-center bg-white">
                <CircularProgress
                  className="text-gospel-gray-300 font-bold z-10"
                  size={30}
                />
              </div>
            ) : (
              <>
                {/* Main Title */}
                <h1 className="text-2xl font-bold mb-4 text-center">
                  Branded Videos
                </h1>

                {/* Table Container */}
                <div className="ooverflow-x-auto flex justify-center">
                  <table className="divide-y divide-gray-200 mx-8 w-full">
                    {/* Table Head */}
                    <thead>
                      <tr className="text-left text-base text-gospel-gray-300">
                        <th className="px-4 py-2"></th>
                        <th className="px-4 py-2 font-medium">Brand</th>
                        <th className="px-4 py-2 font-medium">Views</th>
                        <th className="px-4 py-2 font-medium">Comments</th>
                        <th className="px-4 py-2 font-medium">Likes</th>
                      </tr>
                    </thead>
                    {/* Table Body */}
                    <tbody>
                      {brandVideoInfo
                        .slice(0, showBrandedVideos ? brandVideoInfo.length : 5)
                        .map((item, index) => {
                          return (
                            <tr
                              className={`text-left text-sm text-gray-800  ${
                                index % 2 === 0
                                  ? "bg-gospel-white-100"
                                  : "bg-transparent"
                              }`}
                              key={item.videoId}
                            >
                              <td className="px-4 py-2 flex flex-row gap-4 items-center">
                                <div className="bg-gray-200 flex items-center justify-center">
                                  <img
                                    src={item.thumbnailURL}
                                    alt="Video Thumbnail"
                                    className="max-w-24 h-14 cursor-pointer"
                                    onClick={() => openModal(item.videoId)}
                                  />
                                  <PlayArrow
                                    htmlColor="#fff"
                                    className="absolute cursor-pointer"
                                    onClick={() => openModal(item.videoId)}
                                  />
                                </div>
                                <div className="flex flex-col">
                                  <h3 className="font-medium text-gospel-gray-300">
                                    {item.title}
                                  </h3>
                                  <div className="flex flex-row">
                                    <p className="text-sm text-gospel-gray-200 ">
                                      {item.uploadDate}
                                    </p>
                                    <p className="mx-1 text-sm text-gospel-gray-200 ">
                                      |{" "}
                                    </p>
                                    <p className="text-sm text-gospel-gray-200 ">
                                      {moment(item.uploadTimestamp).format(
                                        "MM/DD/YYY, HH:MM"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </td>

                              {/* Brand */}
                              <td className="px-4 py-2">
                                <a
                                  href={`/brand/${item.brandSlug}`}
                                  className="text-gospel-purple hover:underline text-base"
                                >
                                  {item.sponsor?.length
                                    ? item.sponsor.join(", ")
                                    : ""}
                                </a>
                              </td>
                              {/* Views */}
                              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                                {formatNumberWithCommas(item.viewCount)}
                              </td>
                              {/* Comments */}
                              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                                {formatNumberWithCommas(item.commentCount)}
                              </td>
                              {/* Likes */}
                              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                                {formatNumberWithCommas(item.likeCount)}
                              </td>
                            </tr>
                          );
                        })}
                      {uploadedVideoId && (
                        <ConfigProvider
                          theme={{
                            components: {
                              Modal: {
                                titleFontSize: 18,
                                headerBg: "transparent",
                                contentBg: "",
                                titleColor: "#353b3d",
                                boxShadow: "none",
                                fontFamily: "Montserrat, sans-serif",
                              },
                            },
                          }}
                        >
                          <Modal
                            title=""
                            open={isUploadedYoutubeOpen}
                            width={"fit-content"}
                            height={"fit-content"}
                            footer={null}
                            closeIcon={
                              <Cancel
                                className="absolute bg-gospel-purple text-white rounded-full bottom-0"
                                fontSize="large"
                                onClick={closeChartModal}
                              />
                            }
                            className="text-center"
                          >
                            <ReactPlayer
                              controls
                              width="750px"
                              height={"450px"}
                              url={`https://youtube.com/watch?v=${uploadedVideoId}`}
                            />
                          </Modal>
                        </ConfigProvider>
                      )}
                    </tbody>
                  </table>
                </div>
                {brandVideoInfo?.length > 2 && (
                  <GospelButton
                    className="bg-white px-2 self-center my-2 py-2 w-36 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
                    label={showBrandedVideos ? "Show Less" : "View More"}
                    onClick={toggleShowBrandedVideos}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}

      {/* {Most recent Videos} */}
      <div className="px-4 mb-12">
        <div className="flex flex-col bg-white shadow-md py-8 items-center">
          {loadChannelData ? (
            <div className="p-8 flex items-center justify-center bg-white">
              <CircularProgress
                className="text-gospel-gray-300 font-bold z-10"
                size={30}
              />
            </div>
          ) : (
            <>
              {/* Tabs */}
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      inkBarColor: "#9D63B0",
                      itemSelectedColor: "#353b3d",
                      itemHoverColor: "#353b3d",
                      itemColor: "#adafaf",
                      titleFontSize: 16,
                      fontFamily: "Montserrat",
                    },
                  },
                }}
              >
                <Tabs
                  defaultActiveKey="mostViewed"
                  onChange={(key) => setActiveTab(key as TrendingVideoKey)}
                  items={[
                    { key: "mostViewed", label: "Top 50 Most Viewed Videos" },
                    { key: "mostRecent", label: "Top 50 Most Recent Videos" },
                  ]}
                />
              </ConfigProvider>
              <div className="overflow-x-auto flex justify-center w-full">
                <TrendingVideoTable
                  data={trendingVideoInfo[activeTab]}
                  showTrendingVideos={showTrendingVideos}
                />
              </div>
              {trendingVideoInfo[activeTab]?.length > 2 && (
                <GospelButton
                  className="bg-white px-2 self-center mt-6 py-2 w-28 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
                  label={showTrendingVideos ? "Show Less" : "View More"}
                  onClick={() => setShowTrendingVideos(!showTrendingVideos)}
                />
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
      {selectedVideoId && (
        <ConfigProvider
          theme={{
            components: {
              Modal: {
                titleFontSize: 18,
                headerBg: "transparent",
                contentBg: "",
                titleColor: "#353b3d",
                boxShadow: "none",
                fontFamily: "Montserrat, sans-serif",
              },
            },
          }}
        >
          <Modal
            title=""
            open={isYoutubeOpen}
            width={"fit-content"}
            height={"fit-content"}
            footer={null}
            closeIcon={
              <Cancel
                className="absolute bg-gospel-purple text-white rounded-full bottom-0"
                fontSize="large"
                onClick={closeChartModal}
              />
            }
            className="text-center"
          >
            <ReactPlayer
              controls
              width="750px"
              height={"450px"}
              url={`https://youtube.com/watch?v=${uploadedVideoId}`}
            />
          </Modal>
        </ConfigProvider>
      )}

      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: 22,
              titleColor: "#353b3d",
              fontFamily: "Montserrat, sans-serif",
            },
          },
        }}
      >
        <Modal
          title="Watchlists"
          open={watchlistModalOpen}
          footer={null}
          closeIcon={
            <Cancel
              htmlColor="#000"
              fontSize="medium"
              onClick={() => setWatchlistModalOpen(false)}
            />
          }
          className="text-center"
        >
          <div className="flex flex-col items-start">
            <p className=" text-gospel-gray-300 text-base mb-6">
              Select a list below
            </p>
            <div className="flex flex-col w-full gap-4 h-52 overflow-y-scroll">
              {watchList.map((item) => {
                return (
                  <div
                    className="bg-gospel-white-100 hover:bg-gospel-gray-100 text-gospel-purple hover:text-black py-3 rounded-md cursor-pointer"
                    key={item.id}
                    onClick={() =>
                      addToWatchlist(item.id, channelInfo?.channelId ?? "")
                    }
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
            <div className="mt-7 pt-7 w-full flex flex-col border-t border-gospel-gray-400 ">
              <GospelInput
                type="text"
                onChange={(e) => {
                  setNewWatchlistName(e.target.value);
                  setWatchlistNameError(""); // Clear error when input changes
                }}
                value={newWatchlistName}
                placeholder="Create new list"
                className="w-full h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
              />
              {watchlistNameError && (
                <div className="text-gospel-error-red mb-2 text-sm bg-gospel-red-100 px-3 mt-2 py-2 rounded">
                  {watchlistNameError}
                </div>
              )}
              <div className="flex flex-row items-baseline gap-4">
                <GospelButton
                  onClick={createNewWatchlist}
                  label={
                    loading ? (
                      <CircularProgress
                        className="text-gospel-gray-300 font-bold"
                        size={14}
                      />
                    ) : (
                      "Create"
                    )
                  }
                  disabled={!newWatchlistName.trim()}
                  className="bg-white w-28 py-2.5 mt-2 mb-8 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
                />
                <span>
                  {addWatchlistloading && (
                    <CircularProgress
                      className="text-gospel-gray-300 font-bold"
                      size={14}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        </Modal>
      </ConfigProvider>

      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: 22,
              titleColor: "#353b3d",
              fontFamily: "Montserrat, sans-serif",
            },
          },
        }}
      >
        <Modal
          title="Watchlists"
          open={watchlistListModalOpen}
          footer={null}
          closeIcon={
            <Cancel
              htmlColor="#000"
              fontSize="medium"
              onClick={() => setWatchlistListModalOpen(false)}
            />
          }
          className="text-center"
        >
          <div className="flex flex-col items-start">
            <div className="py-4 flex flex-col w-full gap-4 ">
              {pageInfo?.watchlists?.map((item) => {
                return (
                  <a
                    className="cursor-pointer"
                    href={`/account/watchlists/list/${item.watchlistId}`}
                    key={item.watchlistId}
                  >
                    <div
                      className="bg-gospel-white-100 hover:bg-gospel-gray-100 text-gospel-purple hover:text-black py-3 rounded-md cursor-pointer"
                      key={item.watchlistId}
                    >
                      {item.title}
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  );
}

export default Channel;
