import React, { useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import { MenuItem } from "../../types/account";
import Settings from "./Settings";
import Watchlist from "./Watchlist";
import Reports from "./Reports";
import SettingsIcon from "@mui/icons-material/Settings";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { clearUser } from "../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import Footer from "../../common/Footer/Footer";

const menuItems: MenuItem[] = [
  {
    name: "Settings",
    path: "settings",
    component: Settings,
    icon: <SettingsIcon />,
  },
  {
    name: "Watchlist",
    path: "watchlist",
    component: Watchlist,
    icon: <RemoveRedEyeIcon />,
  },
  {
    name: "Reports",
    path: "reports",
    component: Reports,
    icon: <TextSnippetIcon />,
  },
  {
    name: "Logout",
    path: "logout",
    component: "",
    icon: <LockOpenIcon />,
  },
];

const accountComponents = {
  settings: Settings,
  watchlist: Watchlist,
  reports: Reports,
  logout: null,
};

const Account: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { menu } = useParams<{ menu: string }>();

  useEffect(() => {
    if (menu === "logout") {
      dispatch(clearUser());
      navigate("/login");
    }
  }, [menu]);

  const handleSubMenu = (path: string) => {
    navigate(path);
  };

  const Component = accountComponents[menu as keyof typeof accountComponents];
  return (
    <>
      <div className="overflow-hidden flex flex-col min-h-screen">
        <Header />
        <div className="w-full max-w-screen-xl mx-auto flex mt-28 px-5 mb-16">
          <div className="w-1/4 mr-2.5 rounded bg-white shadow-md pb-7.5 flex-col">
            <h1 className="py-5 px-10 text-[21px] text-gospel-gray-300 ">
              Account
            </h1>
            <ul>
              {menuItems.map((item) => (
                <li className={`px-5 hover:text-black`} key={item.path}>
                  <NavLink
                    to={`/account/${item.path}`}
                    // to={`account/${item.path}`}
                    className={`block py-2.5 px-5 rounded align-middle text-gospel-purple no-underline transition-colors duration-500 ease text-17  cursor-pointer ${
                      menu === item.path
                        ? "bg-black text-white"
                        : "hover:text-black  text-gospel-purple"
                    }`}
                    onClick={() => handleSubMenu(`/account/${item.path}`)}
                  >
                    <span className="mr-[10px]">
                      {React.cloneElement(item.icon, {
                        className: `${
                          menu === item.path
                            ? "text-white "
                            : "text-gospel-purple-200"
                        }`,
                      })}
                    </span>
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-3/4 ml-2.5 rounded bg-white shadow-md">
            <div className="p-10 bg-gospel-purple-200 flex flex-col items-start justify-center text-white overflow-hidden rounded-t bg-account-header-gradient">
              <h1 className="text-2xl">
                {menu && menu.charAt(0).toUpperCase() + menu.slice(1)}
              </h1>
            </div>
            <div className="p-7.5 min-h-[250px]">
              {Component && <Component />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Account;
