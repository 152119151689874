import { KeyboardArrowDown } from "@mui/icons-material";
import { ConfigProvider, Select } from "antd";
import React from "react";

interface SelectOption {
  value: string;
  label: string;
}

interface GospelSelectBoxProps {
  options: SelectOption[];
  mode?: "multiple" | "tags" | undefined;
  value: string;
  onChange: (value: string) => void;
  className?: string;
  containerClass?: string;
  containerStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string;
}

const GospelSelectBox: React.FC<GospelSelectBoxProps> = ({
  options,
  value,
  onChange,
  containerClass,
  containerStyle,
  className = "",
  placeholder = "Select",
  disabled = false,
  mode = undefined,
  style,
  defaultValue,
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextPlaceholder: "#353b3d",
            colorTextQuaternary: "#353b3d",
          },
        },
        token: {
          fontFamily: "Montserrat, sans-serif",
          colorPrimary: "#9D63B0",
        },
      }}
    >
      <div className={containerClass} style={containerStyle}>
        <Select
          className={className}
          mode={mode}
          onChange={onChange}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          showSearch
          disabled={disabled}
          style={style}
          value={value || undefined}
          defaultValue={defaultValue}
          options={options}
          placeholder={placeholder}
        />
      </div>
    </ConfigProvider>
  );
};

export default GospelSelectBox;
