import React from "react";

interface GospelLabelProps {
  label: string;
  className?: string;
}

const GospelLabel: React.FC<GospelLabelProps> = ({ label, className = "" }) => {
  return <span className={className}>{label}</span>;
};

export default GospelLabel;
