export interface Country {
  code: string;
  id: number;
  name: string;
  orderIndex: number;
}

export interface Language {
  code: string;
  name: string;
}

export interface Option {
  value: string;
  label: string;
}

export const formatNumber = (number: number) => {
  if (number >= 1000000000) {
    return number / 1000000000 + "B";
  } else if (number >= 1000000) {
    return number / 1000000 + "M";
  } else if (number >= 1000) {
    return number / 1000 + "K";
  } else {
    return number.toString();
  }
};

export const formatNumberWithCommas = (number: number | string) => {
  if (number === "" || number === null || number === undefined) {
    return ""; // Return an empty string if the input is blank
  }

  if (typeof number !== "number") {
    number = parseInt(number.replace(/,/g, ""), 10); // Convert string to number
    if (isNaN(number)) {
      return ""; // Return an empty string if parsing fails
    }
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Format with commas
};

export const removeCommasAndConvertToInt = (value: string) => {
  return value === "" ? 0 : parseInt(value.replace(/,/g, ""), 10);
};
