import React, { useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import {
  ConfigProvider,
  Collapse,
  CollapseProps,
  DatePicker,
  DatePickerProps,
  Checkbox,
  Tooltip,
} from "antd";
import {
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import GospelLabel from "../../common/GospelLabel/GospelLabel";
import GospelSelectBox from "../../common/GospelSelect/GospelSelect";
import { categoryOptions, dataOptions, shortOptions } from "../../types/charts";
import { get } from "../../utility/api";
import {
  Country,
  Language,
  Option,
  formatNumberWithCommas,
  removeCommasAndConvertToInt,
} from "../../utility/common";
import GospelInput from "../../common/GospelInput/GospelInput";
import GospelButton from "../../common/GospelButton/GospelButton";
import { CircularProgress } from "@mui/material";

interface DiscoverFormData {
  channelName: string;
  categories: string;
  language: string;
  country: string;
  keyword: string;
  keywordslocation: string;
  madeforkids: string;
  minSubscribers: number;
  maxSubscribers: number;
  minViews: number;
  maxViews: number;
  minSubs30Days: number;
  maxSubs30Days: number;
  minVPV30Shorts: number;
  maxVPV30Shorts: number;
  minVPV30Longs: number;
  maxVPV30Longs: number;
  minView30Days: number;
  maxView30Days: number;
  maxShortUploads30Days: number;
  minShortUploads30Days: number;
  maxLongUploads30Days: number;
  minLongUploads30Days: number;
  creationDate?: number | null;
  creationTimeframe: string;
  keywordOptions: string[];
}

export const creationDateOptions = [
  { value: "before", label: "Before" },
  { value: "after", label: "After" },
];


function Discover() {
  const [activeKey, setActiveKey] = useState<string | string[]>("1");
  const [languageList, setLanguageList] = useState<Option[]>([]);
  const [countryList, setCountryList] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [keywordOptions, setKeywordOptions] = useState<string[]>([]);
  const [discoverFormData, setDiscoverFormData] = useState<DiscoverFormData>({
    channelName: "",
    categories: "all",
    language: "",
    country: "",
    keyword: "",
    keywordslocation: "",
    madeforkids: "",
    minSubscribers: 0,
    maxSubscribers: 0,
    minViews: 0,
    maxViews: 0,
    minSubs30Days: 0,
    maxSubs30Days: 0,
    minVPV30Shorts: 0,
    maxVPV30Shorts: 0,
    minVPV30Longs: 0,
    maxVPV30Longs: 0,
    minView30Days: 0,
    maxView30Days: 0,
    maxShortUploads30Days: 0,
    minShortUploads30Days: 0,
    maxLongUploads30Days: 0,
    minLongUploads30Days: 0,
    creationDate: null,
    creationTimeframe: "after",
    keywordOptions: [],
  });

  const handleCheckboxChange = (checkedValues: string[]) => {
    setKeywordOptions(checkedValues);
  };

  const handleInputChange = (field: keyof DiscoverFormData, value: string) => {
    const formattedValue = removeCommasAndConvertToInt(value);
    setDiscoverFormData((prev) => ({
      ...prev,
      [field]: formattedValue,
    }));
  };

  const onCreationDateChange: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    if (date) {
      setDiscoverFormData((prev) => ({
        ...prev,
        creationDate: date.valueOf(),
      }));
    } else {
      setDiscoverFormData((prev) => ({
        ...prev,
        creationDate: null,
      }));
    }
  };

  const onDiscoverData = async () => {
    setLoading(true);
    const params = new URLSearchParams({
      categories: discoverFormData.categories || "all", // Use "all" if categories is not defined
      channel: discoverFormData.channelName,
      country: discoverFormData.country || "", // Default to an empty string if not provided
      creationdate: discoverFormData.creationDate
        ? discoverFormData.creationDate.toString()
        : "",
      creationtimeframe: discoverFormData.creationTimeframe,
      keywords: discoverFormData.keyword,
      keywordslocation: discoverFormData.keywordOptions.join(",") || "", // Default to an empty string if no options
      language: discoverFormData.language || "", // Default to an empty string if not provided
      madeforkids: discoverFormData.madeforkids || "both", // Default to "both" if not provided
      maxsubs: discoverFormData.maxSubscribers
        ? discoverFormData.maxSubscribers.toString()
        : "",
      maxsubs30days: discoverFormData.maxSubs30Days
        ? discoverFormData.maxSubs30Days.toString()
        : "",
      maxuploadslongs: discoverFormData.maxLongUploads30Days
        ? discoverFormData.maxLongUploads30Days.toString()
        : "",
      maxuploadsshorts: discoverFormData.maxShortUploads30Days
        ? discoverFormData.maxShortUploads30Days.toString()
        : "",
      maxviews: discoverFormData.maxViews
        ? discoverFormData.maxViews.toString()
        : "",
      maxviews30days: discoverFormData.maxView30Days
        ? discoverFormData.maxView30Days.toString()
        : "",
      maxvpv30longs: discoverFormData.maxVPV30Longs
        ? discoverFormData.maxVPV30Longs.toString()
        : "",
      maxvpv30shorts: discoverFormData.maxVPV30Shorts
        ? discoverFormData.maxVPV30Shorts.toString()
        : "",
      minsubs: discoverFormData.minSubscribers
        ? discoverFormData.minSubscribers.toString()
        : "",
      minsubs30days: discoverFormData.minSubs30Days
        ? discoverFormData.minSubs30Days.toString()
        : "",
      minuploadslongs: discoverFormData.minLongUploads30Days
        ? discoverFormData.minLongUploads30Days.toString()
        : "",
      minuploadsshorts: discoverFormData.minShortUploads30Days
        ? discoverFormData.minShortUploads30Days.toString()
        : "",
      minviews: discoverFormData.minViews
        ? discoverFormData.minViews.toString()
        : "",
      minviews30days: discoverFormData.minView30Days
        ? discoverFormData.minView30Days.toString()
        : "",
      minvpv30longs: discoverFormData.minVPV30Longs
        ? discoverFormData.minVPV30Longs.toString()
        : "",
      minvpv30shorts: discoverFormData.minVPV30Shorts
        ? discoverFormData.minVPV30Shorts.toString()
        : "",
      limit: "50", // This is fixed
      offset: "0", // This is fixed
      orderby: "desc", // Fixed sorting order
      sortby: "allTimeSubs", // Fixed sorting criteria
    });

    try {
      const response = await get(`/youtube/stats/discover?${params}`);
    } catch (error) {
      console.error("Error fetching discover data:", error);
    } finally {
      setLoading(false);
    }
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div className="flex flex-row mx-6 pb-6 border-b border-gospel-gray-400 justify-between items-center">
          <h1 className="text-xl font-semibold text-gospel-gray text-left ">
            Search Criteria
          </h1>
          {activeKey === "1" ? (
            <button onClick={() => handleToggle("1")}>
              <KeyboardArrowUp fontSize="large" />
            </button>
          ) : (
            <button onClick={() => handleToggle("1")}>
              <KeyboardArrowDown fontSize="large" />
            </button>
          )}
        </div>
      ),
      children: (
        <div>
          <div className="flex flex-wrap mx-2">
            {/* Left Column */}
            <div className="w-1/2">
              <div className="py-2 flex flex-row items-center gap-8">
                <GospelLabel
                  label={"Channel Name:"}
                  className="text-sm text-gray-400 w-36 text-left"
                />
                <GospelInput
                  type="text"
                  onChange={(e) => {
                    setDiscoverFormData((prev) => ({
                      ...prev,
                      channelName: e.target.value,
                    }));
                  }}
                  value={discoverFormData.channelName}
                  placeholder="Enter Channel Name"
                  className="w-[480px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-8">
                <GospelLabel
                  label={"Categories:"}
                  className="text-sm text-gray-400 w-36 text-left"
                />
                <GospelSelectBox
                  options={categoryOptions}
                  value={discoverFormData.categories}
                  onChange={(value) => {
                    setDiscoverFormData((prev) => ({
                      ...prev,
                      categories: value,
                    }));
                  }}
                  containerClass="relative"
                  className="w-[480px] h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-8">
                <GospelLabel
                  label={"Country:"}
                  className="text-sm text-gray-400 w-36 text-left"
                />
                <GospelSelectBox
                  options={countryList}
                  value={discoverFormData.country}
                  onChange={(value) => {
                    setDiscoverFormData((prev) => ({
                      ...prev,
                      country: value,
                    }));
                  }}
                  containerClass="relative"
                  className="w-[480px] h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-8">
                <GospelLabel
                  label={"Language:"}
                  className="text-sm text-gray-400 w-36 text-left"
                />
                <GospelSelectBox
                  options={languageList}
                  value={discoverFormData.language}
                  onChange={(value) => {
                    setDiscoverFormData((prev) => ({
                      ...prev,
                      language: value,
                    }));
                  }}
                  containerClass="relative"
                  className="w-[480px] h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
              </div>
            </div>
            {/* Right Column */}
            <div className="w-1/2">
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Total Subscribers:"}
                  className="text-sm text-gray-400 w-48 text-left"
                />
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minSubscribers", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.minSubscribers
                  )}
                  placeholder="Min"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxSubscribers", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.maxSubscribers
                  )}
                  placeholder="Max"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Total Views:"}
                  className="text-sm text-gray-400 w-48 text-left"
                />
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minViews", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.minViews)}
                  placeholder="Min"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxViews", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.maxViews)}
                  placeholder="Max"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Creation Date:"}
                  className="text-sm text-gray-400 w-48 text-left"
                />
                <GospelSelectBox
                  options={creationDateOptions}
                  value={discoverFormData.creationTimeframe}
                  onChange={(value) =>
                    setDiscoverFormData((prev) => ({
                      ...prev,
                      creationTimeframe: value,
                    }))
                  }
                  containerClass="relative"
                  className="w-[242px] h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
                <span className="text-gray-400 mx-0.5"></span>
                <DatePicker
                  onChange={onCreationDateChange}
                  className="h-10 w-[253px]"
                />
              </div>
            </div>

            <div className="border-b border-gospel-gray-400 w-full my-8" />
            {/* Keywords Column */}
            <div className="w-1/2">
              <div className="py-2 flex flex-row items-center gap-8">
                <GospelLabel
                  label={"Keywords:"}
                  className="text-sm text-gray-400 w-36 text-left"
                />
                <div className="flex flex-col">
                  <GospelInput
                    type="text"
                    onChange={(e) => {
                      setDiscoverFormData((prev) => ({
                        ...prev,
                        keyword: e.target.value,
                      }));
                    }}
                    value={discoverFormData.keyword}
                    placeholder="Enter Keywords"
                    className="w-[480px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                  />
                  <div className="flex flex-row items-start mt-4 gap-8">
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "transperant",
                          colorPrimaryHover: "transperant",
                          colorText: "transperant",
                          fontFamily: "Montserrat, sans-serif",
                          fontSize: 12,
                        },
                      }}
                    >
                      <Checkbox.Group
                        options={["Channel Description", "Channel Keywords"]}
                        onChange={handleCheckboxChange}
                        className="font-normal text-gray-400 font-montserrat text-xs"
                      />
                    </ConfigProvider>
                  </div>
                </div>
              </div>
            </div>
            {/* Made for Kids */}
            <div className="w-1/2">
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Made for Kids:"}
                  className="text-sm text-gray-400 w-44 text-left"
                />
                <GospelSelectBox
                  options={shortOptions}
                  value={discoverFormData.madeforkids}
                  onChange={(value) => {
                    setDiscoverFormData((prev) => ({
                      ...prev,
                      madeforkids: value,
                    }));
                  }}
                  containerClass="relative"
                  className="w-[515px] h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
              </div>
            </div>

            <div className="border-b border-gospel-gray-400 w-full my-8" />
            {/* Subs - 30 Days */}
            <div className="w-1/2">
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Subs - 30 Days:"}
                  className="text-sm text-gray-400 w-40 text-left"
                />
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minSubs30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.minSubs30Days)}
                  placeholder="Min"
                  className="h-10 w-56 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxSubs30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.maxSubs30Days)}
                  placeholder="Max"
                  className="h-10 w-56 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-4">
                <div className="w-40 flex items-start">
                  <GospelLabel
                    label={"VPV30 Shorts:"}
                    className="text-sm text-gray-400 text-left"
                  />
                  <Tooltip
                    placement="top"
                    title={
                      "The average views per video on all the videos uploaded to a channel in the last 30 days."
                    }
                  >
                    <InfoOutlined htmlColor="#e2e3e3" className="mx-1" />
                  </Tooltip>
                </div>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minVPV30Shorts", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.minVPV30Shorts
                  )}
                  placeholder="Min"
                  className="h-10 w-56 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxVPV30Shorts", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.maxVPV30Shorts
                  )}
                  placeholder="Max"
                  className="h-10 w-56 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-4">
                <div className="w-40 flex items-start">
                  <GospelLabel
                    label={"VPV30 Longs:"}
                    className="text-sm text-gray-400 text-left"
                  />
                  <Tooltip
                    placement="top"
                    title={
                      "The average views per video on all the videos uploaded to a channel in the last 30 days."
                    }
                  >
                    <InfoOutlined htmlColor="#e2e3e3" className="mx-1" />
                  </Tooltip>
                </div>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minVPV30Longs", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.minVPV30Longs)}
                  placeholder="Min"
                  className="h-10 w-56 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxVPV30Longs", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.maxVPV30Longs)}
                  placeholder="Max"
                  className="h-10 w-56 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
            </div>
            {/* Right Column */}
            <div className="w-1/2">
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Views - 30 Days:"}
                  className="text-sm text-gray-400 w-48 text-left"
                />
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minView30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.minView30Days)}
                  placeholder="Min"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxView30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.maxView30Days)}
                  placeholder="Max"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Shorts Uploads - 30 Days:"}
                  className="text-sm text-gray-400 w-48 text-left"
                />
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minShortUploads30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.minShortUploads30Days
                  )}
                  placeholder="Min"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxShortUploads30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.maxShortUploads30Days
                  )}
                  placeholder="Max"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Longs Uploads - 30 Days:"}
                  className="text-sm text-gray-400 w-48 text-left"
                />
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minLongUploads30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.minLongUploads30Days
                  )}
                  placeholder="Min"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxLongUploads30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.maxLongUploads30Days
                  )}
                  placeholder="Max"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
            </div>

            <div className="border-b border-gospel-gray-400 w-full my-8 " />
          </div>
          <GospelButton
            className=" text-white h-[40px] w-[90px] rounded-md text-sm font-medium transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple ml-3"
            label={
              loading ? (
                <CircularProgress
                  className="text-gospel-white-100 font-bold"
                  size={14}
                />
              ) : (
                "Search"
              )
            }
            onClick={() => onDiscoverData()}
          />
        </div>
      ),
    },
  ];

  const handleToggle = (key: string | string[]) => {
    setActiveKey(key);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [countryResult, languageResult] = await Promise.all([
          get<Country[]>("gospel/countries/list"),
          get<Language[]>("gospel/languages/list"),
        ]);

        const transformedCountryOptions = [
          { value: "all", label: "All" },
          ...countryResult.map((country) => ({
            value: country.code,
            label: country.name,
          })),
        ];

        const transformedLanguageOptions = [
          { value: "all", label: "All" },
          ...languageResult.map((language) => ({
            value: language.code,
            label: language.name,
          })),
        ];

        setCountryList(transformedCountryOptions);
        setLanguageList(transformedLanguageOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="overflow-hidden flex flex-col min-h-screen">
      <Header />
      <div className="flex flex-col items-center justify-center mt-[70px] md:mt-[70px]">
        <div className="text-center py-4 max-w-screen-md mx-auto overflow-y-auto mt-5">
          <h1 className="text-2xl font-semibold md:text-2xl lg:text-2xl mb-2 text-gospel-gray">
            Discover
          </h1>
        </div>
        <div className="text-center pb-10 w-full">
          <div className="mx-8 py-8 bg-white rounded-md shadow-md">
            <ConfigProvider
              theme={{
                components: {
                  Collapse: {
                    headerPadding: 0,
                    fontFamily: "Montserrat, sans-serif",
                  },
                },
              }}
            >
              <Collapse
                items={items}
                ghost
                expandIcon={() => null}
                defaultActiveKey={["1"]}
                onChange={handleToggle}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Discover;
