import React, { useState } from "react";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import { Dehaze } from "@mui/icons-material";
import type { MenuProps } from "antd";
import GospelButton from "../../common/GospelButton/GospelButton";
import { get } from "../../utility/api";
import GospelSelectBox from "../../common/GospelSelect/GospelSelect";
import { useParams } from "react-router-dom";

import moment from "moment";
import {
  ConfigProvider,
  Dropdown,
  DatePicker,
  Modal,
  Tooltip,
  AutoComplete,
} from "antd";

import Highcharts, { SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import { CircularProgress } from "@mui/material";
import ReactPlayer from "react-player";
import { channelChartTabs } from "../Channel/ChannelUtils";
import dayjs, { Dayjs } from "dayjs";
import { quickDateRanges } from "../../common/utils";
import { SearchChannelData } from "../../types/search";
import { debounce } from "lodash";

HighchartsMore(Highcharts);

const chartLineWidth: number = 5;

const { RangePicker } = DatePicker;

const items: MenuProps["items"] = [
  {
    key: "1",
    label: <button onClick={() => {}}>Save/View Comparisons</button>,
  },
  {
    key: "2",
    label: <button onClick={() => {}}>Save to Dashboard</button>,
  },
];

const chartInterval: string[] = ["daily", "weekly", "monthly", "all-Time"];

const options = quickDateRanges.map((item) => ({
  value: item.value,
  label: item.label,
}));

function Compare() {
  const [loading, setLoading] = useState<boolean>(false); // Loading indicator

  const [selectedValue, setSelectedValue] = useState<string>("");
  const [selectedTabs, setSelectedTabs] = useState<number[]>([
    channelChartTabs[0].key,
  ]);
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().subtract(30, "days"),
    endDate: dayjs(),
    key: "selection",
  });
  const [selectedInterval, setSelectedInterval] = useState<string>(
    chartInterval[0]
  );
  const [loadingChart, setLoadingChart] = useState<boolean>(false);

  const [chartData, setChartData] = useState<Highcharts.Options>({
    chart: {
      type: "line",
    },
    title: {
      text: undefined,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        type: "line",
        name: channelChartTabs[0].label,
        // data: [9, 4, 7, 1, 6],
        color: channelChartTabs[0].dataColor,
        lineWidth: chartLineWidth,
      },
    ],
    tooltip: {
      useHTML: true,
      backgroundColor: "transparent", // Remove background color
      borderWidth: 0, // Remove border
      shadow: false, // Remove shadow
    },
    xAxis: {
      type: "linear",
      crosshair: {
        color: "black",
        width: 4,
      },
      categories: [],
      labels: {
        format: "{value:%b %e}",
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: [
      {
        title: {
          text: "Views",
        },
        min: 0,
      },
    ],
    plotOptions: {
      series: {
        zIndex: 100,
        clip: false,
      },
    },
  });

  const [channelOptions, setChannelOptions] = useState<
    { value: string; label: React.ReactNode }[]
  >([]);

  const fetchChannel = debounce(async (searchKey: string) => {
    if (!searchKey.trim()) {
      setChannelOptions([]);
      return;
    }

    setLoading(true);

    try {
      const result = await get<SearchChannelData[]>(
        `/youtube/channel/search?cat=channel&search_term=${searchKey.toLowerCase()}`
      );
      if (result) {
        // Deduplicate channels based on `channelId`
        const uniqueChannels = Array.from(
          new Map(
            result.map((channel) => [channel.channelId, channel])
          ).values()
        );
        // Map to AutoComplete options with unique keys
        const fetchedOptions = uniqueChannels.map((channel) => ({
          value: channel.channelTitle, // Value for AutoComplete
          label: (
            <div className="flex flex-row items-center gap-2 my-2">
              <img
                src={channel.channelImage}
                alt={channel.channelTitle}
                className="w-12 h-12 rounded-full"
              />
              <span className="flex flex-col">
                <span className="font-montserrat text-gospel-gray-300 font-semibold">
                  {channel.channelTitle}
                </span>
                <span className="font-montserrat text-gospel-gray-300 font-normal">
                  {`${channel.allTimeSubs?.toLocaleString() ?? 0} Subscribers`}
                </span>
              </span>
            </div>
          ),
          key: channel.channelId, // Ensure the key is unique
        }));

        setChannelOptions(fetchedOptions);
      } else {
        setChannelOptions([]);
      }
    } catch (error) {
      console.error("Error fetching channels:", error);
      setChannelOptions([]);
    } finally {
      setLoading(false);
    }
  }, 300);

  const handleTabClick = (key: number) => {
    setSelectedTabs((prevSelectedTabs) => {
      const sTabs = prevSelectedTabs.includes(key)
        ? prevSelectedTabs.filter((tabKey) => tabKey !== key)
        : [...prevSelectedTabs, key];

      return sTabs;
    });
  };

  const handleSelectChange = (value: string) => {
    const tempStartDate =
      quickDateRanges.find((e) => e.value === value)?.startDate ?? dayjs();
    const tempEndDate =
      quickDateRanges.find((e) => e.value === value)?.endDate ?? dayjs();

    setSelectedValue(value);
    setDateRange((prev) => {
      return {
        ...prev,
        startDate: tempStartDate,
        endDate: tempEndDate,
      };
    });
  };

  const handleRangeChange = (
    dates: [Dayjs | null, Dayjs | null] | null,
    dateStrings: [string, string]
  ) => {
    const tempStartDate = dates?.[0] ?? dayjs();
    const tempEndDate = dates?.[1] ?? dayjs();

    setSelectedValue("");
    setDateRange((prev) => {
      return {
        ...prev,
        startDate: tempStartDate,
        endDate: tempEndDate,
      };
    });
  };

  return (
    <div className="overflow-hidden flex flex-col min-h-screen">
      <Header />
      <div className="flex items-center justify-center mt-[70px] md:mt-[70px]">
        {/* Adjust margin-top to match the header height */}
        <div className="text-center py-4 mx-8 w-full overflow-y-auto mt-5">
          {/* max-w-screen-md sets maximum width for content, overflow-y-auto enables scrolling */}
          <h1 className="text-2xl font-semibold md:text-2xl lg:text-2xl mb-2 text-gospel-gray">
            Compare
          </h1>

          <div className="text-center py-8 px-4  bg-white rounded-md shadow-md mt-8 ">
            <div className="flex justify-end px-4">
              <ConfigProvider
                theme={{
                  token: {
                    fontFamily: "Montserrat, sans-serif",
                    controlItemBgHover: "#9D63B0",
                    fontSize: 13,
                  },
                }}
              >
                <Dropdown
                  menu={{ items }}
                  placement="bottomRight"
                  arrow={{ pointAtCenter: true }}
                >
                  <Dehaze />
                </Dropdown>
              </ConfigProvider>
            </div>

            {/* Input Field */}
            <div className="flex items-center  justify-center flex-col md:flex-row xl:gap-0 lg:gap-0 md:gap-0 gap-1 text-gospel-gray-300">
              <AutoComplete
                style={{ width: "25%", fontFamily: "Montserrat, sans-serif" }}
                className="custom-autocomplete"
                options={
                  loading
                    ? [
                        {
                          value: "loading",
                          label: (
                            <div className="flex items-center justify-center py-2">
                              <CircularProgress
                                className="text-gospel-gray-300 font-bold z-10"
                                size={20}
                              />
                            </div>
                          ),
                        },
                      ]
                    : channelOptions
                }
                placeholder="Search channel name, username, etc."
                onSelect={(value) => {
                  console.log("Selected:", value); // Handle the selected value here
                }}
                onSearch={(value) => {
                  fetchChannel(value); // Trigger the debounced fetch
                }}
                filterOption={false} // Disable default filtering
              />

              {/* Search Button */}
              <GospelButton
                className="text-gospel-purple h-[40px] w-[90px] mt-2 rounded-md text-sm font-medium cursor-pointer  bg-transparent border border-gospel-gray-100 ml-3"
                label="Add"
                onClick={() => {}}
              />
            </div>

            <div className="flex justify-center items-center p-4 w-full mt-8 border border-gospel-gray-100 rounded-md">
              <span className=" bg-gray-100 p-8 text-center text-gray-400 text-sm leading-[1.35] w-full max-w-md rounded-md">
                You have no channels selected. Please add channels using the
                search field above.
              </span>
            </div>

            <div className="bg-white z-50 min-h-400px py-10 px-3 shadow-md my-4">
              <div className="flex flex-wrap justify-center items-center gap-3">
                {channelChartTabs.map((tab) => (
                  <div
                    className="w-full sm:w-auto md:w-auto text-center mb-4"
                    key={tab.key}
                  >
                    <GospelButton
                      className={`h-[40px] rounded-md text-sm font-medium transition duration-500 cursor-pointer px-6 ${
                        selectedTabs.includes(tab.key)
                          ? "text-white"
                          : "text-gospel-gray-400 bg-gospel-white-100 hover:text-gospel-gray-300"
                      }`}
                      style={{
                        backgroundColor: selectedTabs.includes(tab.key)
                          ? tab.dataColor
                          : "",
                      }}
                      label={tab.label}
                      onClick={() => handleTabClick(tab.key)}
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-wrap md:flex-nowrap">
                  {chartInterval.map((interval) => (
                    <button
                      key={interval}
                      className={`bg-white flex items-center cursor-pointer text-sm px-4 py-3 transition-colors duration-500 ease-in-out hover:text-gospel-gray-300  font-bold${
                        selectedInterval === interval
                          ? "bg-gospel-gray-300  text-gospel-gray-300"
                          : "bg-white text-gospel-gray-400"
                      }`}
                      onClick={() => {
                        setSelectedInterval(interval);
                      }}
                      onKeyUp={() => {}}
                    >
                      <span
                        className={`${
                          selectedInterval === interval
                            ? "bg-gospel-purple border-gospel-purple"
                            : "bg-white border-gospel-gray-500"
                        } rounded-full inline-block w-4 h-4 mr-1 border-2  hover:text-gray-500 transition-border duration-500 ease-in-out relative`}
                      ></span>
                      {interval.charAt(0).toUpperCase() + interval.slice(1)}
                    </button>
                  ))}
                </div>

                <div className="mt-4 md:mt-0 flex flex-col md:flex-row items-center gap-4">
                  <RangePicker
                    className="appearance-none w-64 h-10 px-3 text-sm text-gospel-purple font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                    defaultValue={[dateRange.startDate, dateRange.endDate]}
                    value={[dateRange.startDate, dateRange.endDate]}
                    onChange={handleRangeChange}
                    format="MM/DD/YYYY"
                  />

                  <div className="relative inline-block text-left">
                    <GospelSelectBox
                      options={options}
                      value={selectedValue}
                      onChange={handleSelectChange}
                      containerClass="relative max-w-[450px]"
                      className="w-60 h-10"
                      placeholder="Quick Date Ranges"
                    />
                  </div>
                </div>
              </div>
              <div
                className={`bg-white z-50 min-h-400px py-10 px-3 shadow-md my-4 relative  ${
                  loadingChart ? "opacity-20" : ""
                }`}
              >
                {loadingChart && (
                  <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                    <CircularProgress
                      className="text-gospel-gray-300 font-bold z-10"
                      size={30}
                    />
                  </div>
                )}
                <HighchartsReact highcharts={Highcharts} options={chartData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Compare;
