import Home from "@mui/icons-material/Home";
import YouTube from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import Facebook from "@mui/icons-material/Facebook";
import dayjs from "dayjs";
import moment from "moment";

type Platform = "twitter" | "instagram" | "youtube" | "facebook" | "home";

const getPlatform = (url: string): Platform => {
  if (url.includes("twitter.com")) return "twitter";
  if (url.includes("instagram.com")) return "instagram";
  if (url.includes("youtube.com")) return "youtube";
  if (url.includes("facebook.com")) return "facebook";
  return "home";
};

const platformIcons: Record<Platform, JSX.Element> = {
  twitter: <XIcon style={{ fontSize: 28 }} />,
  instagram: <InstagramIcon style={{ fontSize: 28 }} />,
  youtube: <YouTube style={{ fontSize: 28 }} />,
  facebook: <Facebook style={{ fontSize: 28 }} />,
  home: <Home style={{ fontSize: 30 }} />,
};

const quickDateRanges = [
  {
    value: "last7days",
    label: "Last 7 Days",
    startDate: dayjs().subtract(7, "days"),
    endDate: dayjs(),
  },
  {
    value: "last30days",
    label: "Last 30 Days",
    startDate: dayjs().subtract(30, "days"),
    endDate: dayjs(),
  },
  {
    value: "last90days",
    label: "Last 90 Days",
    startDate: dayjs().subtract(90, "days"),
    endDate: dayjs(),
  },
  {
    value: "last6months",
    label: "Last 6 Months",
    startDate: dayjs().subtract(180, "days"), // Explicitly use 180 days
    endDate: dayjs(),
  },
  {
    value: "last12months",
    label: "Last 12 Months",
    startDate: dayjs().subtract(365, "days"), // Explicitly use 365 days
    endDate: dayjs(),
  },
];

const getDynamicTickInterval = (
  startDate: number,
  endDate: number,
  quickRange: string = ""
) => {
  const oneDay = 24 * 3600 * 1000; // One day in milliseconds
  const dateDifference = moment(endDate).diff(moment(startDate), "days"); // Proper day difference
  // Explicit conditions for quickRange
  if (quickRange) {
    switch (quickRange) {
      case "last7days":
        return oneDay; // 1 tick per day
      case "last30days":
        return oneDay; // 1 tick per day
      case "last90days":
        return 2 * oneDay; // 1 tick every 2 days
      case "last6months":
        return 2 * oneDay; // 1 tick every 2 days
      case "last12months":
        return 7 * oneDay; // 1 tick per week
      default:
        break;
    }
  }
  // General logic for date ranges
  if (dateDifference <= 7) {
    return oneDay; // 1 tick per day for up to 7 days
  } else if (dateDifference <= 30) {
    return oneDay; // 1 tick per day for up to 30 days
  } else if (dateDifference <= 90) {
    return 7 * oneDay; // 1 tick per week for up to 90 days
  } else if (dateDifference <= 180) {
    return 7 * oneDay; // 1 tick per week for up to 6 months
  } else if (dateDifference <= 365) {
    return 30 * oneDay; // 1 tick per month for up to 1 year
  } else if (dateDifference <= 1095) {
    return 30 * oneDay; // 1 tick every 3 months for up to 3 years
  } else {
    return 30 * oneDay; // 1 tick every 6 months for ranges longer than 3 years
  }
};

const determineLabelRotation = (daysDifference: number, interval: string) => {
  if (daysDifference <= 7 || daysDifference <= 30) {
    return 0;
  } else if (
    daysDifference <= 90 ||
    daysDifference <= 180 ||
    daysDifference <= 365
  ) {
    return -45; // Steeper rotation for yearly data
  } else if (daysDifference <= 1825) {
    return -30; // Moderate rotation for half-year data
  }
  return 0; // No rotation for smaller ranges
};

function formatLargeNumber(value: number): string {
  if (value >= 1_000_000_000) {
    return Math.floor(value / 1_000_000_000) + "B"; // Round down for billions
  } else if (value >= 1_000_000) {
    return Math.floor(value / 1_000_000) + "M"; // Round down for millions
  } else if (value >= 1_000) {
    return Math.floor(value / 1_000) + "K"; // Round down for thousands
  } else {
    return Math.floor(value).toString(); // Show integer values for less than 1000
  }
}

function formatLargeNumberWithDecimal(value: number): string {
  if (value >= 1_000_000_000) {
    return (value / 1_000_000_000).toFixed(2) + "B"; // Billions with 2 decimal places
  } else if (value >= 1_000_000) {
    return (value / 1_000_000).toFixed(2) + "M"; // Millions with 2 decimal places
  } else if (value >= 1_000) {
    return (value / 1_000).toFixed(2) + "K"; // Thousands with 2 decimal places
  } else {
    return value.toFixed(2); // Show the full value with 2 decimals for less than 1000
  }
}

export {
  getPlatform,
  platformIcons,
  getDynamicTickInterval,
  determineLabelRotation,
  quickDateRanges,
  formatLargeNumber,
  formatLargeNumberWithDecimal,
};
export type { Platform };
